import React from "react";

// nodejs library that concatenates classes
import classnames from "classnames";

import $ from 'jquery';
import api from './../../../Api.js';

// reactstrap components
import {UncontrolledAlert,Button,Card,CardHeader,CardBody,FormGroup,Form,Input,InputGroupAddon,InputGroupText,InputGroup,Container,Row,Col} from "reactstrap";

// core components
import AuthHeader from "./../../../components/Headers/AuthHeader.jsx";

class Login extends React.Component {

  constructor(props){
    super(props);
    this.state = {loginErro:"",logando:false};
    window.loginPage = this;
  }

  async login(event){

      event.preventDefault();
      this.setState({logando:true});
      api.login({email:$("#email").val(),password:$("#password").val()}).then((res) => {
        this.props.loginSuccess(res.data.session,res.data.user);
        setTimeout(function(){ this.setState({logando:false}); }.bind(this), 1200);
      }).catch(error => {
          console.log(error.data);
          console.log(error);
          console.log(error.response);
          if(error.response.status === 400){ this.setState({loginErro:""}); this.setState({loginErro:error.response.data[0].msg}); }
          this.setState({logando:false});
      });

  }

  render() {
    return (<React.Fragment>
        <AuthHeader title={window.language('bem_vindo')} lead={window.language('informe_as_credencias')}/>
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form" type="POST" onSubmit={  (event) => this.login(event) } >
                    {

                      (this.state.loginErro !== "") &&
                      <UncontrolledAlert color="danger" className="animated tada" ><span className="alert-text ml-1"><strong>Ops!</strong> {this.state.loginErro}</span></UncontrolledAlert>

                    }
                    <FormGroup className={classnames("mb-3",{focused:this.state.focusedEmail})} >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Email" type="email" id="email" onFocus={() => this.setState({focusedEmail:true})} onBlur={() => this.setState({ focusedEmail: false })} />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className={classnames({focused:this.state.focusedPassword})} >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Password" type="password" id="password" onFocus={() => this.setState({focusedPassword:true})} onBlur={() => this.setState({ focusedPassword:false}) }/>
                      </InputGroup>
                    </FormGroup>

                    <Row>
                        <Col md={7}>{window.language('esqueci_minha_senha')}</Col>
                        <Col md={5}><Button color="primary" type="submit" style={{float:'right'}}>{window.language('entrar')}</Button></Col>
                    </Row>
                    <div className="text-center"></div>
                    <CardHeader className="bg-transparent" style={{marginTop:'22px',paddingTop:'0px',marginBottom:'0px',paddingBottom:'0px'}}>
                      <div className="text-muted text-center mt-2 mb-3"><small>{window.language('escolha_uma_linguagem')}</small></div>
                      <div className="btn-wrapper text-center">
                        {

                          window.app.state.languages.map((value,key) => {

                            var img = "/flags/"+value.img;
                            return(
                              <Button onClick={ () => window.app.getLanguageTexts(value.file) } style={{marginBottom:'15px'}} className="btn-neutral btn-icon" color="default" >
                                <span className="btn-inner--icon mr-1"><img alt="..." src={img} /></span>
                                <span className="btn-inner--text">{value.name}</span>
                              </Button>)

                          })

                        }
                      </div>
                    </CardHeader>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>);
  }
}

export default Login;
