import React from "react";
import api from "./../../../../Api.js";
import {Badge,Card,CardHeader,CardBody,Container,Row,Col,Button,Modal,DropdownToggle,DropdownMenu,DropdownItem,UncontrolledDropdown} from "reactstrap"; 

import {BlockPicker} from 'react-color'; 

class NewClass extends React.Component {

    constructor(props){
        
        super(props);
        this.state = {unit:this.props.unit,sequence:this.props.sequence};
        this.state.name = "";
        this.state.color = "#fff";
        this.state.topBarColor = "#fff";
        this.state.error = "";
        
        this.postChapter = this.postChapter.bind(this);
        this.putChapter = this.putChapter.bind(this);
        this.saveChapter = this.saveChapter.bind(this);
        this.getChapter = this.getChapter.bind(this);

        window.newclass = this;
    
    }

   

    getChapter(){
 
        api.getChapter(this.props.id).then(async (res) => { 

            this.setState({name:res.data.name,color:res.data.color,topBarColor:res.data.topBarColor});

        }).catch((error) => {

            try{ this.setState({error:error.response.data.msg}) }catch(error){ console.error(error); }

        });


    }

    componentDidMount(){

        if(this.props.id !== undefined){ this.getChapter(); }

    }
 
    postChapter(){

        api.postChapter(this.state).then(async (res) => { 

            this.props.success();

        }).catch((error) => {

            try{ this.setState({error:error.response.data.msg}) }catch(error){ console.error(error); }

        });

    }

    putChapter(){

        api.putChapter(this.props.id,this.state).then(async (res) => { 

            this.props.success();
             

        }).catch((error) => {

            try{ this.setState({error:error.response.data.msg}) }catch(error){ console.error(error); }

        });

    }

    saveChapter(e){
        
        e.preventDefault();
        if(this.props.id === undefined){ this.postChapter(); }else{ this.putChapter(); }

    }

    componentWillMount(){}
    
    render() {

        return(
        <React.Fragment>
            <Modal className="modal-dialog-centered" isOpen={this.props.open} >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <div className="modal-header">
                            <h6 className="modal-title" id="modal-title-default">{(this.props.id === undefined ? 'Nova classe':'Editar classe')}</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.props.close()}><span aria-hidden={true}>×</span> </button>
                        </div>
                        <form onSubmit={ (event) => this.saveChapter(event) }>
                            <div className="px-lg-5 card-body">
                                {

                                    (this.state.error !== "") &&
                                    <div class="alert alert-danger fade show" role="alert"><strong>Ops!</strong> {this.state.error}</div>

                                }
                                <div>
                                    <h5>Nome:</h5>
                                    <div class="input-group-merge input-group-alternative input-group"> 
                                        <input onChange={(e) => { this.setState({name:e.target.value})}} value={this.state.name} placeholder="" type="text" class="form-control-alternative form-control" />
                                    </div>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-md-6" style={{textAlign:'center'}}>
                                        <h5>Cor</h5>
                                        <div style={{display:'flex',justifyContent:'center'}}>
                                            <BlockPicker color={this.state.color} onChangeComplete={(e) => {this.setState({color:e.hex}) }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{textAlign:'center'}}>
                                        <h5>Cor da barra do topo</h5>
                                        <div style={{display:'flex',justifyContent:'center'}}>
                                            <BlockPicker color={this.state.topBarColor} onChangeComplete={(e) => {this.setState({topBarColor:e.hex}) }}/>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center" style={{marginTop:'25px'}}><button type="submit" class="btn btn-primary">{(this.props.id === undefined ? 'Cadastrar capítulo':'Salvar alterações')}</button></div>
                            </div>
                        </form>
                    </Card>
                </div>
            </Modal>
        </React.Fragment>);

    }

}
export default NewClass;
