import React, { Component } from 'react';
import Chart from 'react-apexcharts' 

class App extends Component {

  constructor(props) {
    super(props);

    this.updateCharts = this.updateCharts.bind(this)

    this.state = {



           options: {
          labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
          plotOptions: {
              radar: {
                  size: 140,
                  polygons: {
                    strokeColor: '#e9e9e9',
                    fill: {
                        colors: ['#f8f8f8', '#fff']
                    }
                  }
              }
          },
          title: {
              text: 'Radar with Polygon Fill'
          },
          colors: ['#FF4560'],
          markers: {
              size: 4,
              colors: ['#fff'],
              strokeColor: '#FF4560',
              strokeWidth: 2,
          },
          tooltip: {
              y: {
                  formatter: function(val) {
                      return val
                  }   
              }
          },
          yaxis: {
              tickAmount: 7,
              labels: {
                  formatter: function(val, i) {
                      if(i % 2 === 0) {
                          return val
                      } else {
                          return ''
                      }
                  }
              }
          }
        },
        series: [{
            name: 'Series 1',
            data: [20, 100, 40, 30, 50, 80, 33],
        }]


      ,
      optionsMixedChart: {
        chart: {
          id: 'basic-bar',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            endingShape: 'arrow'
          }
        },
        stroke: {
          width: [4, 0, 0],
        },
        xaxis: {
          categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        markers: {
          size: 6,
          strokeWidth: 3,
          fillOpacity: 0,
          strokeOpacity: 0,
          hover: {
            size: 8
          }
        },
        yaxis: {
          tickAmount: 5,
          min: 0,
          max: 100
        }
      },
      seriesMixedChart: [{
        name: 'series-1',
        type: 'line',
        data: [30, 40, 25, 50, 49, 21, 70, 51]
      }, {
        name: 'series-2',
        type: 'column',
        data: [23, 12, 54, 61, 32, 56, 81, 19]
      }, {
        name: 'series-3',
        type: 'column',
        data: [62, 12, 45, 55, 76, 41, 23, 43]
      }],
      optionsRadial: {
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: '70%',
              background: '#fff',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              background: '#fff',
              strokeWidth: '67%',
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },

            dataLabels: {
              showOn: 'always',
              name: {
                offsetY: -20,
                show: true,
                color: '#888',
                fontSize: '13px'
              },
              value: {
                formatter: function (val) {
                  return val;
                },
                color: '#111',
                fontSize: '30px',
                show: true,
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#ABE5A1'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        labels: ['Percent'],

      },
      seriesRadial: [76],
      optionsBar: {
        chart: {
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          dropShadow: {
            enabled: true
          }
        },
        stroke: {
          width: 0,
        },
        xaxis: {
          categories: ['Fav Color'],
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        fill: {
          opacity: 1,
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: "vertical",
            shadeIntensity: 0.35,
            gradientToColors: undefined,
            inverseColors: false,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [90, 0, 100]
          },
        },

        legend: {
          position: 'bottom',
          horizontalAlign: 'right',
        }
      },
      seriesBar: [{
        name: 'blue',
        data: [32]
      }, {
        name: 'green',
        data: [41]
      }, {
        name: 'yellow',
        data: [12]
      }, {
        name: 'red',
        data: [65]
      }]
    }
  }

  updateCharts() {
  
    
  }

  render() {

    return (
      <React.Fragment>
        
        <Chart options={this.state.options} series={this.state.series} type="radar" width="100%" />
        
        {/*

          <div className="row">
            <div className="col mixed-chart">
              <Chart options={this.state.optionsMixedChart} series={this.state.seriesMixedChart} type="radar" width="500" />
            </div>

            <div className="col radial-chart">
              <Chart options={this.state.optionsRadial} series={this.state.seriesRadial} type="radialBar" width="280" />
            </div>
          </div>

          <div className="row">
            <div className="col percentage-chart">
              <Chart options={this.state.optionsBar} height={140} series={this.state.seriesBar} type="bar" width={500} />
            </div>

            <p className="col">
              <button onClick={this.updateCharts}>Update!</button>
            </p>
          </div>
  
        */}            
       
      </React.Fragment>
    );
  }
}

export default App;
