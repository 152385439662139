import React from "react";
import api from "./../../../../Api.js";
import {Card,Modal} from "reactstrap"; 

class NewPage extends React.Component {

    constructor(props){
        
        super(props);
        this.state = {sequence:this.props.sequence};

        if(this.props.idChapter !== undefined){

            this.state.chapter = this.props.idChapter;

        }

        this.state.name = "";
        this.state.color = "#fff";
        this.state.topBarColor = "#fff";
        this.state.error = "";
        
        this.postPage = this.postPage.bind(this);
        this.putPage = this.putPage.bind(this);
        this.savePage = this.savePage.bind(this);
        this.getPage = this.getPage.bind(this);

        window.newpage = this;
    
    }

    getPage(){
 
        api.getPage(this.props.id).then(async (res) => { 

            this.setState({name:res.data.name,icon:res.data.icon});

        }).catch((error) => {

            try{ this.setState({error:error.response.data.msg}) }catch(error){ console.error(error); }

        });


    }

    componentDidMount(){
 
        if(this.props.id !== undefined){ this.getPage(); }

    }
 
    postPage(){

        api.postPage(this.state).then(async (res) => { 

            this.props.success();

        }).catch((error) => {

            try{ this.setState({error:error.response.data.msg}) }catch(error){ console.error(error); }

        });

    }

    putPage(){

        api.putPage(this.props.id,this.state).then(async (res) => { 

            this.props.success();
             

        }).catch((error) => {

            try{ this.setState({error:error.response.data.msg}) }catch(error){ console.error(error); }

        });

    }

    savePage(e){
        
        e.preventDefault();
        if(this.props.id === undefined){ this.postPage(); }else{ this.putPage(); }

    }

    componentWillMount(){}
    
    render() {

        return(
        <React.Fragment>
            <Modal className="modal-dialog-centered" isOpen={this.props.open} >
                <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                        <div className="modal-header">
                            <h6 className="modal-title" id="modal-title-default">{(this.props.id === undefined ? 'Nova página':'Editar página')}</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.props.close()}><span aria-hidden={true}>×</span> </button>
                        </div>
                        <form onSubmit={ (event) => this.savePage(event) }>
                            <div className="px-lg-5 card-body">
                                {

                                    (this.state.error !== "") &&
                                    <div class="alert alert-danger fade show" role="alert"><strong>Ops!</strong> {this.state.error}</div>

                                }
                                
                                <div>
                                    <h5>Nome:</h5>
                                    <div class="input-group-merge input-group-alternative input-group"> 
                                        <input onChange={(e) => { this.setState({name:e.target.value})}} value={this.state.name} placeholder="" type="text" class="form-control-alternative form-control" />
                                    </div>
                                </div>
                                <br/>
                                <div>
                                    <h5>Ícone:</h5>
                                    <div class="input-group-merge input-group-alternative input-group"> 
                                        <select onChange={(e) => { this.setState({icon:e.target.value})}} value={this.state.icon} placeholder="" type="text" class="form-control-alternative form-control">
                                            <option value=""></option>
                                            <option value="text">Texto</option>
                                            <option value="audio">Áudio</option>
                                            <option value="video">Vídeo</option>
                                        </select>
                                    </div>
                                </div>
                                <br/>
                                <div class="text-center"><button type="submit" class="btn btn-primary">{(this.props.id === undefined ? 'Cadastrar página':'Salvar alterações')}</button></div>
                            </div>
                        </form>
                    </Card>
                </div>
            </Modal>
        </React.Fragment>);

    }

}
export default NewPage;
