import axios from 'axios';

//Não verifica o certificado ssl
var api = axios.create({secure: false });

var apis = {
	
	login: (data) => api.post(sessionStorage.getItem('backend')+'/auth/password',data,{headers:{session:sessionStorage.getItem('session')}}),
	users: () => api.get(sessionStorage.getItem('backend')+'/user',{headers:{session:sessionStorage.getItem('session')}}),
	levelAcess: () => api.get(sessionStorage.getItem('backend')+'/accesslevel',{headers:{session:sessionStorage.getItem('session')}}),
	listLanguages: () => api.get('/language/index.json'),
	languageText: (file) => api.get('/language/'+file),

	//para atualizar os dados de um usuario
	updateUser: (id,data) => api.put(sessionStorage.getItem('backend')+'/user/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	//Parar criar um novo usuario
	postUser: (data) => api.post(sessionStorage.getItem('backend')+'/user/',data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para remover o usuario
	deleteUser: (id) => api.delete(sessionStorage.getItem('backend')+'/user/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para enviar arquivo
	uploadFile: (data) => api.delete(sessionStorage.getItem('backend')+'/upload/',data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para criar um novo curso
	postCourse: (data) => api.post(sessionStorage.getItem('backend')+'/course/',data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para listar todos os cursos
	course: () => api.get(sessionStorage.getItem('backend')+'/course/',{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar os dados de um curso
	getCourse: (id) => api.get(sessionStorage.getItem('backend')+'/course/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para atualizar os dados de um curso
	putCourse: (id,data) => api.put(sessionStorage.getItem('backend')+'/course/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar os dados de uma unit
 	getUnit: (id) => api.get(sessionStorage.getItem('backend')+'/unit/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar as units de um curso
	getUnitCourse: (id) => api.get(sessionStorage.getItem('backend')+'/unit/course/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar as lessons de uma unit
	getLessonUnit: (id) => api.get(sessionStorage.getItem('backend')+'/lesson/unit/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar uma lesson especifica
	getLesson: (id) => api.get(sessionStorage.getItem('backend')+'/lesson/'+id,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para cadastrar uma unit
 	postUnit: (data) => api.post(sessionStorage.getItem('backend')+'/unit/',data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para editar uma unit
 	putUnit: (id,data) => api.put(sessionStorage.getItem('backend')+'/unit/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para remover uma unit
 	deleteUnit: (id) => api.delete(sessionStorage.getItem('backend')+'/unit/'+id,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para alterar a ordem da unit
 	postLesson: (data) => api.post(sessionStorage.getItem('backend')+'/lesson/',data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para cadastrar uma lesson
 	putLesson: (id,data) => api.put(sessionStorage.getItem('backend')+'/lesson/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para editar uma lesson
 	deleteLesson: (id) => api.delete(sessionStorage.getItem('backend')+'/lesson/'+id,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para remover uma lesson
 	changeOrderUnit: (item,index) => api.put(sessionStorage.getItem('backend')+'/unit/updateorder/'+item,{index:index},{headers:{session:sessionStorage.getItem('session')}}),

 	//Para remover uma lesson
 	changeOrderLesson: (item,index) => api.put(sessionStorage.getItem('backend')+'/lesson/updateorder/'+item,{index:index},{headers:{session:sessionStorage.getItem('session')}}),

 	//Para pegar todos os vídeos de um curso
 	getVideoCourse: (id) => api.get(sessionStorage.getItem('backend')+'/video/course/'+id,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para cadastrar um novo víode
 	postVideo: (data) => api.post(sessionStorage.getItem('backend')+'/video/',data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para atualizar um video
 	putVideo: (id,data) => api.put(sessionStorage.getItem('backend')+'/video/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para remover um vídeo
 	deleteVideo: (id) => api.delete(sessionStorage.getItem('backend')+'/video/'+id,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para pegar os dados de um vídeo
 	dataVideo: (id) => api.get(sessionStorage.getItem('backend')+'/video/'+id,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para verificar o acesso do usuario no sisstema
 	verifyAcess: (id) => api.get(sessionStorage.getItem('backend')+'/auth/verify',{headers: {session: sessionStorage.getItem('session')}}),

 	//Para cadastrar um novo quiz no curso
 	postQuiz: (item) => api.post(sessionStorage.getItem('backend')+'/quiz/',item,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para pegar todos os quiz de um curso
 	getQuizCourse: (course) => api.get(sessionStorage.getItem('backend')+'/quiz/course/'+course,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para atualizar o quiz
 	putQuiz: (id,data) => api.put(sessionStorage.getItem('backend')+'/quiz/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para deletar um quiz
 	deleteQuiz: (id) => api.delete(sessionStorage.getItem('backend')+'/quiz/'+id,{headers:{session:sessionStorage.getItem('session')}}),
 	
 	//Para pegar as questões de um quiz
 	getQuizQuestions: (id) => api.get(sessionStorage.getItem('backend')+'/quizquestion/quiz/'+id,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para pegar os dados de um quiz especifico
 	getQuiz: (id) => api.get(sessionStorage.getItem('backend')+'/quiz/'+id,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para cadastrar uma nova questão
 	postQuestion: (data) => api.post(sessionStorage.getItem('backend')+'/quizquestion/',data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para Atualizar uma questão
 	deleteQuestion: (id) => api.delete(sessionStorage.getItem('backend')+'/quizquestion/'+id,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para atualizar uma questão
 	putQuestion: (id,data) => api.put(sessionStorage.getItem('backend')+'/quizquestion/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para pegar todas as license
 	getAllLicense: (id) => api.get(sessionStorage.getItem('backend')+'/license/',{headers:{session:sessionStorage.getItem('session')}}),

 	//Para pegar todas as license
 	postLicense: (data) => api.post(sessionStorage.getItem('backend')+'/license/',data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para pegar todas as license
 	putLicense: (id,data) => api.put(sessionStorage.getItem('backend')+'/license/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para pegar os niveis de acesso de um grupo
 	getAccessLevelGroup: (group) => api.get(sessionStorage.getItem('backend')+'/accesslevel/group/'+group,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para pergar todas as permissões
 	getAllPermission: () => api.get(sessionStorage.getItem('backend')+'/accesslevel/permission/',{headers:{session:sessionStorage.getItem('session')}}),

 	//Para cadastrar um grupo de permissão
 	postGroupPermission: (obj) => api.post(sessionStorage.getItem('backend')+'/accesslevel/group',obj,{headers:{session:sessionStorage.getItem('session')}}),

	//Para editar um grupo de permissão
 	putGroupPermission: (id,data) => api.put(sessionStorage.getItem('backend')+'/accesslevel/group/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para salvar as permissões do grupo
 	savePermissionGroup: (grupo,data)  => api.put(sessionStorage.getItem('backend')+'/accesslevel/save/permission/'+grupo,data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para cadastrar o grupo de aula
	postLessonGroup: (data)  => api.post(sessionStorage.getItem('backend')+'/lessongroup',data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para salvar o grupo de aula
	putLessonGroup: (id,data)  => api.put(sessionStorage.getItem('backend')+'/lessongroup/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar os cursos disponíveis em um licença
	getCourseLicense: (id)  => api.get(sessionStorage.getItem('backend')+'/license/listcourses/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para atualizar a licença do curso
	putCourseLicense: (idLicense,courses)  => api.put(sessionStorage.getItem('backend')+'/license/savecourses/'+idLicense,{courses:courses},{headers:{session:sessionStorage.getItem('session')}}),

	//Para listar quais licenças usam o curso
	getListslicenseCourse: (course)  => api.get(sessionStorage.getItem('backend')+'/course/listslicense/'+course,{headers:{session:sessionStorage.getItem('session')}}),
	
	duplicateUnit: (data)  => api.post(sessionStorage.getItem('backend')+'/unit/copy/',data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar os métodos de avaliação
	getEvaluations: ()  => api.get(sessionStorage.getItem('backend')+'/evaluation/',{headers:{session:sessionStorage.getItem('session')}}),

	postEvaluations: (data)  => api.post(sessionStorage.getItem('backend')+'/evaluation',data,{headers:{session:sessionStorage.getItem('session')}}),
	
	putEvaluations: (id,data)  => api.put(sessionStorage.getItem('backend')+'/evaluation/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	
	deleteEvaluation: (id)  => api.delete(sessionStorage.getItem('backend')+'/evaluation/'+id,{headers:{session:sessionStorage.getItem('session')}}),
 
	postChapter: (data)  => api.post(sessionStorage.getItem('backend')+'/chapter/',data,{headers:{session:sessionStorage.getItem('session')}}),
	putChapter: (id,data)  => api.put(sessionStorage.getItem('backend')+'/chapter/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deleteChapter: (id)  => api.delete(sessionStorage.getItem('backend')+'/chapter/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	getChapter: (id)  => api.get(sessionStorage.getItem('backend')+'/chapter/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	getChaptersUnit: (id)  => api.get(sessionStorage.getItem('backend')+'/chapter/fromunit/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	postPage: (data)  => api.post(sessionStorage.getItem('backend')+'/page/',data,{headers:{session:sessionStorage.getItem('session')}}),
	putPage: (id,data)  => api.put(sessionStorage.getItem('backend')+'/page/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deletePage: (id)  => api.delete(sessionStorage.getItem('backend')+'/page/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	getPage: (id)  => api.get(sessionStorage.getItem('backend')+'/page/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	getLessonsPage: (id)  => api.get(sessionStorage.getItem('backend')+'/lessons/frompage/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	
	postMedia: (data)  => api.post(sessionStorage.getItem('backend')+'/media/',data,{headers:{session:sessionStorage.getItem('session')}}),
	putMedia: (id,data)  => api.put(sessionStorage.getItem('backend')+'/media/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deleteMedia: (id)  => api.delete(sessionStorage.getItem('backend')+'/media/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	getMedia: (course,type)  => api.get(sessionStorage.getItem('backend')+'/media/'+course+'?type='+type,{headers:{session:sessionStorage.getItem('session')}}),
	
	getHabilidades: (data)  => api.get(sessionStorage.getItem('backend')+'/bncc_habilidades/',{headers:{session:sessionStorage.getItem('session')}}),
	postHabilidades: (data)  => api.post(sessionStorage.getItem('backend')+'/bncc_habilidades/',data,{headers:{session:sessionStorage.getItem('session')}}),
	putHabilidades: (id,data)  => api.put(sessionStorage.getItem('backend')+'/bncc_habilidades/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deleteHabilidade: (id)  => api.delete(sessionStorage.getItem('backend')+'/bncc_habilidades/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	listHabilidades: ()  => api.get(sessionStorage.getItem('backend')+'/bncc_habilidades',{headers:{session:sessionStorage.getItem('session')}}),

	postCompetencias: (data)  => api.post(sessionStorage.getItem('backend')+'/bncc_competencias/',data,{headers:{session:sessionStorage.getItem('session')}}),
	putCompetencias: (id,data)  => api.put(sessionStorage.getItem('backend')+'/bncc_competencias/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deleteCompetencias: (id)  => api.delete(sessionStorage.getItem('backend')+'/bncc_competencias/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	listCompetencias: ()  => api.get(sessionStorage.getItem('backend')+'/bncc_competencias',{headers:{session:sessionStorage.getItem('session')}}),

}

export default apis;