import React from "react";
import api from "./../../Api.js";
import classnames from "classnames";
import $ from 'jquery';
import {Alert,Modal,CardBody,FormGroup,InputGroupAddon,Form,Input,InputGroupText,InputGroup,Button,Card,CardHeader,Table,Container,Row,Col,UncontrolledTooltip} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import axios from 'axios';

class AssessmentMethods extends React.Component {

  	constructor(props){

  		super(props);
  		this.state = {
			evaluations:[],
		};

        this.getEvaluations = this.getEvaluations.bind(this);
		 
		this.deleteEvaluation = this.deleteEvaluation.bind(this);
        this.put = this.put.bind(this);
        this.post = this.post.bind(this);

    	window.assessment = this;

  	}

	deleteEvaluation(){
		
		console.log('deleteEvaluationdeleteEvaluation');

		api.deleteEvaluation(this.state.deletion).then((res) => {
 
			this.getEvaluations();
			this.setState({openDialogRemove:false})
	
			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">A avaliação foi deletada.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);
	
		}).catch((error) => {
	
			window.Swal.fire('Ops!',error.response.data.msg,'error');
			this.setState({load:false});
	
		});

	}
	
	async post(){

		if(this.state.load === true){ return false; }

		api.postEvaluations(this.state.dialog).then((res) => {

			this.setState({load:false,openModalNew:false});
			this.getEvaluations();

			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">A competencia foi cadastrada.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);

		}).catch((error) => {

			window.Swal.fire('Ops!',error.response.data.msg,'error');
			this.setState({load:false});

		});

	}

	async put(){
 		 
		if(this.state.load === true){ return false; }

		api.putEvaluations(this.state.dialog.id,this.state.dialog).then((res) => {

			this.setState({load:false,openModalNew:false});
			this.getEvaluations();

			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">As alterações foram salva.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);
	
		}).catch((error) => {
	
			 window.Swal.fire('Ops!',error.response.data.msg,'error');
			this.setState({load:false});
	
		});

	}
 
	putEvaluations(){}

	async getEvaluations(){

		//Para salvar as permissões no grupo
		await api.getEvaluations().then((res) => {

			this.setState({evaluations:res.data});

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);

			this.setState({loading:false});

	 		if(error.response.status === 400){

		  		this.setState({groupError:""});
	  			this.setState({groupError:error.response.data.msg});

			}

		});

	}


	//para criar um novo grupo no sistem,
	async componentDidMount(){

		await this.getEvaluations();
		  
	}

	save(event){

		event.preventDefault();
		if(this.state.dialog.idedit === undefined){ this.post(); }else{ this.put(); }

	}

	new(){

		this.setState({openModalNew:true,dialog:{}});

	}

	changeFileInput(event,htmlFor){

		console.log(event);
		console.log(event.target.value);
		$("label[for='"+htmlFor+"']").html(event.target.value);
		console.log("label[for='"+htmlFor+"']");
	
	}
	
	async uploadImg(input){

		let formData = new FormData();
		var fileInput = this.state.fileInput;
		var input = $('input[name="custom_file_1"]')[0];
		 
		var file = input.files;
	
		//seta o erro caso nenhum arquivo tenha sido informado
		if(file.length === 0){  
		
		  return false;

		}
	
		//removo o erro caso tenha
		   
		formData.append('files', file[0]); 
		formData.append('name', file[0].name); 
	
		axios.post(sessionStorage.getItem('backend')+'/upload',formData,{headers:{session:sessionStorage.getItem('session'),'Content-Type': 'multipart/form-data'},onUploadProgress:progressEvent => {
	 
	
		}}).then(function(res){
			
			this.state.dialog.icon = res.data.id;

			$('.iconMethod').attr('src',res.data.urldownload);
			$('.iconMethod').fadeIn(0);
	   
		}.bind(this)).catch(function(res){
	
			console.log('FAILURE!!');
		 
	
		}.bind(this));
	
	  }

	render(){

		const handleChange = name => event => {
	        var dialog = this.state.dialog;
	        dialog[name] = event.target.value;
	        this.setState({dialog:dialog});
	    }

		return(
		<React.Fragment>

		<div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
		<SimpleHeader name="Metódos de avaliação" way={[{name:"Métodos",url:"/permission"}]} buttons={<React.Fragment>
			<Button className="btn-neutral btn-round btn-icon" color="default" href="#pablo" id="tooltip969372949" onClick={() => this.new()} size="sm">
                    <span className="btn-inner--icon mr-1"><i class="fas fa-plus"></i></span>
                    <span className="btn-inner--text">Nova avalição</span>
                  </Button></React.Fragment>} />
		
        <Col md={12}>
			<Row> 
			<Col md={12}>
				<Container className="mt--6" fluid>
					<Card>
					<CardHeader className="border-0">
						<Row>
						<Col xs="6"><h3 className="mb-0">Avaliação</h3></Col>
						<Col className="text-right" xs="6" hidden>
							<Button className="btn-neutral btn-round btn-icon" color="default" href="#pablo" id="tooltip969372949" onClick={() => this.newGroup('school')} size="sm">
							<span className="btn-inner--icon mr-1"><i class="fas fa-user-plus"></i></span>
							<span className="btn-inner--text">Criar avaliação</span>
							</Button>
						</Col>
						</Row>
					</CardHeader>
					<Table className="align-items-center table-flush" responsive>
						<thead className="thead-light">
						<tr>
							<th>Nome</th>
							<th>Ícone</th>
							<th>Ações</th>
						</tr>
						</thead>
						<tbody>
							{
								
								this.state.evaluations.map((evaluation) => {

									return(
										<tr>
											<td>{evaluation.name}</td>
											<td>
												{

													(evaluation.iconData !== undefined) &&
													<img src={evaluation.iconData.urldownload} alt="..." className="iconMethod" style={{maxHeight:'28px'}} />

												}
											</td>
											<td>
												<Button className="btn-icon" color="default" size="sm" type="button" onClick={() => {

													evaluation.idedit = evaluation.id;
													this.setState({dialog:evaluation,openModalNew:true});

												}} >
													<span className="btn-inner--icon"><i className="fas fa-edit" /></span>
												</Button>
												<Button className="btn-icon" color="default" size="sm" type="button" onClick={() =>this.setState({openDialogRemove:true,deletion:evaluation.id}) } >
													<span className="btn-inner--icon"><i className="fas fa-trash" /></span>
												</Button>
											</td>
										</tr>
									)

								})

							}
						</tbody>
						{

							(this.state.evaluations.length === 0) &&
							<tbody>
								<tr>
									<td colSpan="3" className="text-center">Nenhuma avaliação encontrada.</td>
								</tr>
							</tbody>

						}
					</Table>
					</Card>
				</Container>
			</Col> 
			</Row>
		</Col>
		
			{

				(this.state.openModalNew === true) &&
				<Modal className="modal-dialog-centered" isOpen={true} >
					<div className="modal-body p-0">
						<Card className="bg-secondary border-0 mb-0">
						<div className="modal-header">
						<h6 className="modal-title" id="modal-title-default">{this.state.dialog.idedit === undefined ? "Cadastrar" : "Editar"}</h6>
						<button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({openModalNew:false})}><span aria-hidden={true}>×</span> </button>
					</div>
					<CardBody className="px-lg-5">
						<Form role="form" type="post" onSubmit={ (event) => this.save(event) }>
							<Col md={12}>
								<h5>Alias</h5>
								<FormGroup className={classnames("mb-3",{focused:this.state.alias})} >
								<InputGroup className="input-group-merge input-group-alternative">
									<InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
									<Input type="text" onChange={handleChange('id')} value={ this.state.dialog.id } onFocus={() => this.setState({ alias: true }) } onBlur={() => this.setState({ alias: false }) } />
								</InputGroup>
								</FormGroup>
							</Col>
							<Col md={12}>
								<h5>Nome</h5>
								<FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
								<InputGroup className="input-group-merge input-group-alternative">
									<InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
									<Input type="text" onChange={handleChange('name')} value={ this.state.dialog.name } onFocus={() => this.setState({ focusedName: true }) } onBlur={() => this.setState({ focusedName: false }) } />
								</InputGroup>
								</FormGroup>
							</Col>
							<Col md={12}>
								<h5 style={{marginTop:'10px'}}>Ícone:</h5>
							</Col>
							<Col md={12}>
								<center><img src="" alt="..." className="iconMethod" style={{display:'none',maxWidth:'130px',marginBottom:'35px',marginTop:'35px'}} /></center>
							</Col>
							<Col md={12} >
								<div style={{display:"flex"}}>
									<div className="custom-file form-control-sm">
										<input onChange={ (event) => { this.changeFileInput(event,'custom_file_1'); this.uploadImg('custom_file_1'); } } name="custom_file_1" className="custom-file-input" id="custom_file_1" type="file" />
										<label className="custom-file-label " htmlFor="custom_file_1">Selecionar arquivo</label>
									</div>
									<Button onClick={ () => this.uploadImg('custom_file_1') } color="secondary" type="button" style={{marginLeft:'5px',height:'44px'}}><i className="ni ni-cloud-upload-96"></i> Enviar</Button>
								</div>
							</Col>
						
							<div className="text-center">
								<Button className="my-4" color="default" type="button" onClick={ () => this.setState({openModalNew:false}) } >Cancelar</Button>
								<Button className="my-4" color="primary" type="submit">
									{

										this.state.dialog.idedit === undefined ? "Cadastrar" : "Salvar alterações"

									}
								</Button>
							</div>

						</Form>
					</CardBody>
					</Card>
				</div>
				</Modal>

			}
        
			<Modal className="modal-dialog-centered modal-danger" onClose={() => { this.setState({openDialogRemove:false}) }} contentClassName="bg-gradient-danger" isOpen={this.state.openDialogRemove} >
				<div className="modal-header">
					<h6 className="modal-title" id="modal-title-notification">Sua atenção é requirida.</h6>
					<button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => { this.setState({openDialogRemove:false}); }}>
						<span aria-hidden={true} onClick={ () => this.setState({openDialogRemove:false}) }>×</span>
					</button>
				</div>
				<div className="modal-body">
					<div className="py-3 text-center">
						<i class="fas fa-exclamation-triangle fa-x3" style={{fontSize:'45px'}}></i>
						<h4 className="heading mt-4">VOCÊ DEVE LER ISTO!</h4>
						<p>Se você prosseguir, todos os dados serão removidos, essa ação é irreversível.</p>
					</div>
				</div>
				<div className="modal-footer">
					<Button className="btn-white" color="default" type="button" onClick={ () => { console.log('this.deleteEvaluation()'); this.deleteEvaluation(); } }>Ok, Remova</Button>
					<Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={ () => this.setState({openDialogRemove:false}) }>Cancelar e fechar</Button>
				</div>
			</Modal>
			
		  </React.Fragment>
		);
	}
}
export default AssessmentMethods;