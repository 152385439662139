import Alternative from "./views/pages/dashboards/Alternative.jsx";
import Dashboard from "./views/pages/dashboards/Dashboard.jsx";

const routes = [
  {
    collapse: true,
    name: "Dashboards",
    icon: "ni ni-shop text-primary",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        layout: "/admin"
      },
      {
        path: "/alternative-dashboard",
        name: "Alternative",
        component: Alternative,
        layout: "/admin"
      }
    ]
  } ,
  {
    collapse: true,
    name: "Cursos",
    icon: "fas fa-graduation-cap text-info",
    state: "componentsCollapse",
    views: [
      {
        path: "/buttons",
        name: "",
        style:{display:'none'},
        layout: "/admin"
      },
      {
        path: "/course/:course/videos",
        name: "",
        style:{display:'none'},
        layout: "/admin"
      },
      {
        path: "/course/:course/unit/:unit/lesson/:lesson",
        name: "",
        style:{display:'none'},
        layout: "/admin"
      },
      {
        path: "/course/newcourse",
        name: "Novo curso",
        layout: "/admin"
      },
      {
        path: "/course/edit/:id",
        name: "Novo curso",
        style:{display:'none'},
        layout: "/admin"
      },
      {
        path: "/course/:course/unit/:unit/mountlesson",
        name: "Profile",
        style:{display:'none'},
        layout: "/admin"
      },
      {
        path: "/course/:course",
        name: "Profile",
        style:{display:'none'},
        layout: "/admin"
      },
      {
        path: "/course/",
        name: "Listar cursos",
        layout: "/admin"
      }
    ]
  },
 

  {
    path: "/users",
    name: "Usuários",
    icon: "far fa-user text-green", 
    layout: "/admin"
  },
  {
    path: "/permission",
    name: "Grupos e Permissões",
    icon: "far fa-user text-green", 
    layout: "/admin"
  }


];

export default routes;
