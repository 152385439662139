import React from "react";
import api from "./../../../../Api.js";
import {Badge,Card,CardHeader,CardBody,Container,Row,Col,Button,Modal,Dropdown,DropdownToggle,DropdownMenu,DropdownItem,UncontrolledDropdown} from "reactstrap";
import SimpleHeader from "./../../../../components/Headers/SimpleHeader.jsx";
import {DragDropContext,Droppable,Draggable} from 'react-beautiful-dnd';

//import NewClass from "./NewClass.jsx";
import NewChapter from "./NewChapter.jsx";
import NewPage from "./NewPage.jsx";
 
import NotificationAlert from "react-notification-alert";
import { Link } from "react-router-dom";

const arrayMove = require('array-move');
const Swal = require('sweetalert2');

class ManagerChapter extends React.Component {

    constructor(props){
        
        super(props);
        this.state = {newPage:false,chapters:[],course:{},unit:{},loadCouse:false,loadClasses:false,loadUnit:false,editId:undefined,newClass:false};

        this.state.dialogChapters = {};

        this.getChapters = this.getChapters.bind(this);
        this.getCourse = this.getCourse.bind(this);
        this.getUnit = this.getUnit.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDragEnd2 = this.onDragEnd2.bind(this);
        this.deletePage = this.deletePage.bind(this);

        window.managerchapter = this;
    
    }

    componentDidMount(){
         
        this.getChapters(); 
        this.getCourse(); 
        this.getUnit(); 
    
    }
 
    getCourse(){ api.getCourse(this.props.match.params.course).then(async (res) => { this.setState({course:res.data}); }).catch((error) => {}); }

    getUnit(){ api.getUnit(this.props.match.params.unit).then(async (res) => { this.setState({unit:res.data}); }).catch((error) => {}); }
    
    getChapters(){ 
        
        api.getChaptersUnit(this.props.match.params.unit).then(async (res) => { 
            
            console.log(res.data);
            this.setState({chapters:res.data});

        }).catch((error) => {}); 

    }

    componentWillMount(){}

    onDragEnd(data){

        try{

            this.state.chapters = arrayMove(this.state.chapters,data.source.index,data.destination.index);
            for(let index = 0; index < this.state.chapters.length; index++){

                api.putChapter(this.state.chapters[index].id,{sequence:index}).then(async(res) => { console.log(res.data); }).catch((error) => { console.log(error.response); });
                
            }

        }catch(error){ console.error(error); }
        
    }

    onDragEnd2(idchapter,data){

        var index = this.state.chapters.map(function(x){ return x.id; }).indexOf(idchapter);
        if(index !== -1){

            try{

                console.log(this.state.chapters[index]);
                this.state.chapters[index].pages = arrayMove(this.state.chapters[index].pages,data.source.index,data.destination.index);

                var pages = this.state.chapters[index].pages;

                for(let index2 = 0; index2 < pages.length; index2++){
    
                    api.putPage(pages[index2].id,{sequence:index2}).then(async(res) => { console.log(res.data); }).catch((error) => { console.log(error.response.data); });
                    
                }
    
            }catch(error){ console.error(error); }

        }

        //console.log(array);
        console.log(data);

         

    }


    removeChapter(id){
 
        Swal.fire({
            title: 'Tem certeza?',
            text: " ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, remover!',
            cancelButtonText:"Não, cancelar"
        }).then((result) => {

            if(result.value){
                
                api.deleteChapter(id).then((res) => {
                    
                    let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O capítulo foi removido.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
                    this.refs.notificationAlert.notificationAlert(options);

                    this.getChapters(); 
        
                }).catch(error => {
        
                    console.log(error.data);
                    console.log(error);
                    console.log(error.response);
        
                });

            }

        });

    }

    deletePage(id){
 
        Swal.fire({
            title: 'Tem certeza?',
            text: " ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, remover!',
            cancelButtonText:"Não, cancelar"
        }).then((result) => {

            if(result.value){
                
                api.deletePage(id).then((res) => {
                    
                    let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">A page foi removida.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
                    this.refs.notificationAlert.notificationAlert(options);

                    this.getChapters(); 
        
                }).catch(error => {
        
                    console.log(error.data);
                    console.log(error);
                    console.log(error.response);
        
                });

            }

        });

    }

     

    render() {

        console.log('teste');
        console.log(this.state.chapters);

        const rs = (() => {
            
            this.setState({newPage:false});
            this.getChapters(); 

        });

        return (<React.Fragment>
                <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
                <SimpleHeader name="Aulas" way={[{name:"Cursos",url:"/course"},{name:this.state.course.name,url:'/course/'+this.state.course.id},{name:"Aula ("+this.state.unit.name+")",url:this.props.history.location.pathname}]} buttons={<React.Fragment>
                    <button onClick={() => this.setState({editId:undefined,newChapter:true}) } type="button" className="btn-primary btn-round btn-icon  btn btn-secondary btn-sm" ><span className="btn-inner--text"><i className="fas fa-plus"></i> Novo capítulo</span></button>
                </React.Fragment>} />
                <Container className="mt--6" fluid>
                    <Row>
                        <div className="col-lg-12 cima_card_class" style={{display:'flex',justifyContent:'center'}}>
                            {

                                (this.state.chapters.length === 0) &&
                                <React.Fragment><center><h3 style={{color:"#fff",marginTop:'10px'}}>Nenhuma capítulo foi encontrada.</h3></center></React.Fragment>
                            }
                        </div>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="all-droppables" direction="horizontal" type="column">
                                {

                                    (provided, snapshot) => (
                                    <div className="react-trello-lane" ref={provided.innerRef} {...provided.droppableProps}>
                                        {
                                    
                                            this.state.chapters.map((value, index) => (

                                                 

                                            <Draggable key={index} draggableId={'colum_'+value.id} index={index}>
                                                {(provided, snapshot) => (
                                                <div className="card_class" ref={provided.innerRef}  {...provided.draggableProps}  >
                                                    
                                                    <div className={'card_class_title'}>
                                                        <i className="fas fa-arrows-alt" {...provided.dragHandleProps}></i> Capítulo {index+1} - {value.name}
                                                        <i onClick={() => { this.setState({newPage:true,editPageId:undefined,totpages:value.pages.length,idChapter:value.id}) }} className="fas fa-plus icon_add_dd"></i>
                                                        <Dropdown toggle={(e) => this.setState({opendpid:(this.state.opendpid !== value.id ? value.id:'')})} isOpen={(this.state.opendpid === value.id ? true:false)}>
                                                            <DropdownToggle color="secondary"><i className="fas fa-ellipsis-v"></i></DropdownToggle>
                                                            <DropdownMenu>
                                                                <button onClick={() => this.setState({opendpid:'',editId:value.id,newChapter:true})} style={{cursor:'pointer'}} type="button" className="dropdown-item"><i className="fas fa-pencil-alt"></i> Editar</button>
                                                                <button onClick={() => { this.setState({opendpid:''}); this.removeChapter(value.id); }} style={{cursor:'pointer'}} type="button" className="dropdown-item"><i className="fas fa-trash-alt"></i> Remover</button>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </div>

                                                    <div className="chapter_List" style={{marginTop:(value.pages.length === 0 ? '0px':'10px'),marginBottom:(value.pages.length === 0 ? '0px':'10px')}}>
                                                        {

                                                            (value.pages.length === 0) &&
                                                            <React.Fragment>
                                                                <center className="nenhum_chapter">Nenhum chapter foi cadastrado</center>
                                                            </React.Fragment>

                                                        }
                                                        {

                                                            /**/
                                                            <DragDropContext onDragEnd={(e) => this.onDragEnd2(value.id,e)}>
                                                                <Droppable droppableId={"drop_colum_"+value.id} direction="vertical" type="cards">
                                                                    {

                                                                        (provided3) => (
                                                                        <div style={{ }} ref={provided3.innerRef} {...provided3.droppableProps}>
                                                                            {

                                                                                value.pages.map((value2,index2) => {

                                                                                    var link = '/course/'+this.state.course.id+'/unit/'+this.state.unit.id+'/managerchapter/'+value2.id;

                                                                                    return(
                                                                                        <Draggable key={value2.id} draggableId={'opportunity_'+value2.id}  index={index2}>
                                                                                        {
                                                                                            (provided3) => (<div {...provided3.draggableProps} ref={provided3.innerRef} className="chapter">
                                                                                                
                                                                                                <div><i {...provided3.dragHandleProps} className="fas fa-arrows-alt chatp_mover"></i></div>
                                                                                                <div style={{cursor:'pointer'}}  className="icon_chapter">
                                                                                                    <Link to={link} style={{color:"#fff"}}>
                                                                                                        {

                                                                                                            (value2.icon === "text") &&
                                                                                                            <i className="fab fa-readme"></i> 

                                                                                                        }
                                                                                                        {

                                                                                                            (value2.icon === "audio") &&
                                                                                                            <i className="fas fa-volume-up"></i>

                                                                                                        }
                                                                                                        {

                                                                                                            (value2.icon === "video") &&
                                                                                                            <i className="fab fa-youtube"></i>

                                                                                                        }
                                                                                                    </Link>
                                                                                                </div>
                                                                                                <div style={{cursor:'pointer'}} className="chapter_name"><Link to={link} style={{color:"#000"}}>{value2.name}</Link></div>
                                                                                                <div className="trash">
                                                                                                    <Link to={link} style={{color:'#525f7f'}}><i className="fas fa-list-ol" style={{cursor:'pointer',marginRight:'6px'}}></i></Link>
                                                                                                    <i onClick={() => { this.setState({newPage:true,editPageId:value2.id,totpages:value.pages.length,classIdChapter:undefined}); }} className="fas fa-pencil-alt" style={{marginRight:'6px',cursor:'pointer'}}></i>
                                                                                                    <i onClick={() => { this.deletePage(value2.id); }} className="far fa-trash-alt"  style={{cursor:'pointer'}}></i>
                                                                                                </div>
                                                                                            </div>)
                                                                                        }
                                                                                        </Draggable>
                                                                                    )

                                                                                })

                                                                            }
                                                                        </div>)

                                                                    }
                                                                </Droppable>
                                                            </DragDropContext>

                                                             

                                                        }
                                                        
                                                    </div>
                                                </div>)}
                                            </Draggable>))

                                        }
                                    </div>)

                                }
                            </Droppable>
                        </DragDropContext>

                    </Row>
                </Container>
                
                {

                    //para criar nova class
                    (this.state.newChapter === true) &&
                    <NewChapter sequence={this.state.chapters.length+1} unit={this.props.match.params.unit} id={this.state.editId} success={() => {

                        if(this.state.editId === undefined){
                            
                            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O capítulo foi cadastrado.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
                            this.refs.notificationAlert.notificationAlert(options);

                        }

                        if(this.state.editId !== undefined){
                            
                            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O capítulo foi editado.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
                            this.refs.notificationAlert.notificationAlert(options);

                        }
                        
                        this.getChapters(); 
                        this.setState({newChapter:false}); 
                    
                    }} error={() => {  }} id={this.state.editId} close={() => this.setState({newChapter:false}) } open={this.state.newChapter} />

                }

                {
                    
                    (this.state.newPage === true) &&
                    <NewPage idChapter={this.state.idChapter} id={this.state.editPageId} sequence={this.state.totpages} success={() => {

                        if(this.state.editId === undefined){
                            
                            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">A chapter foi cadastrada.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
                            this.refs.notificationAlert.notificationAlert(options);

                        }

                        if(this.state.editId !== undefined){
                            
                            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">A chapter foi editada.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
                            this.refs.notificationAlert.notificationAlert(options);

                        }
                        
                        rs();

                    }} error={() => {  }} close={() => this.setState({newPage:false}) } open={this.state.newPage} />
                    
                }

        </React.Fragment>);

    }

}
export default ManagerChapter;
