import React from "react";
import api from "./../../Api.js";

import $ from 'jquery';
import {UncontrolledAlert,CardBody,FormGroup,Input,Button,Card,CardHeader,Progress,Container,Row,Col} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import axios from 'axios';
import { RingLoader } from 'react-spinners';

class NewCourse extends React.Component {

  	constructor(props){

		super(props);
		this.state = {showCard:false,erroCourse:"",fileInput:[{erro:"",progress:""},{erro:"",progress:""},{erro:"",progress:""}],dialog:{name:"",description:"",icon:"",cover:"",background:"",status:""}};
		this.uploadImg = this.uploadImg.bind(this);
		this.setProgress = this.setProgress.bind(this);
		this.postCourse = this.postCourse.bind(this);
		this.getCourse = this.getCourse.bind(this);
		this.saveCourse = this.saveCourse.bind(this);
		this.putCourse = this.putCourse.bind(this);
		window.newCourse = this;

	}

	//Para fazer o upload do arquivo no input file
	async uploadImg(input){

		let formData = new FormData();
		var fileInput = this.state.fileInput;
		var row = $('.section_file[name="'+input+'"]');
		input = row.find('input[type="file"]');
		var n = row.attr('n');
		var file = input[0].files;

		//seta o erro caso nenhum arquivo tenha sido informado
 		if(file.length === 0){  
 			fileInput[n].erro = "";
 			this.setState({fileInput:fileInput});
 			setTimeout(function(){ fileInput[n].erro = "Você deve escolher um arquivo."; this.setState({fileInput:fileInput}); }.bind(this), 1);
 			return false;
 		}

 		//removo o erro caso tenha
 		fileInput[n].erro = "";
 		this.setState({fileInput:fileInput});

 		formData.append('files', file[0]); 
		formData.append('name', file[0].name);
		this.setProgress(0,n);

		axios.post(sessionStorage.getItem('backend')+'/upload',formData,{headers:{session:sessionStorage.getItem('session'),'Content-Type': 'multipart/form-data'},onUploadProgress:progressEvent => {

			this.setProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total),n)

	    }}).then(function(res){

	    	row.find('.imgup').attr('src',res.data.urldownload)
	    	this.setProgress('',n);

	    	if(parseInt(n) === 0){ this.state.dialog.icon = res.data.id; }
	    	if(parseInt(n) === 1){ this.state.dialog.cover = res.data.id; }
	    	if(parseInt(n) === 2){ this.state.dialog.background = res.data.id; }

	  	}.bind(this)).catch(function(res){

	    	console.log('FAILURE!!');
	    	this.setProgress('',n);

	  	}.bind(this));

	}

	//Para setar o progresso da barra
	setProgress(progress,n){

		var fileInput = this.state.fileInput;
		fileInput[n].progress = progress;
		this.setState({fileInput:fileInput});
		console.log(progress);

	}

	//Quando mudar o arquivo no input, muda o texto no label
	changeFileInput(event,htmlFor){

		console.log(event);
		console.log(event.target.value);
		$("label[for='"+htmlFor+"']").html(event.target.value);
		console.log("label[for='"+htmlFor+"']");

	}

	//Para postar o curso no banco de dados
	async postCourse(){

		await api.postCourse(this.state.dialog).then((res) => {

			this.props.history.push("/course/"+res.data.course.id);
			console.log(res.data);
			return true;

		}).catch(error => {
 
	        console.log(error);
	        console.log(error.response);

         	if(error.response.status === 400){

	          	let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops, não foi possível cadastrar o curso.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
          		this.refs.notificationAlert.notificationAlert(options);

          		this.setState({erroCourse:''});
          		setTimeout(function(){ this.setState({erroCourse:error.response.data.msg}); }.bind(this), 1);

	        }
	        return false;
		});

	}

	//Para salvar as alterações do curso no banco de dados
	async putCourse(){

		await api.putCourse(this.props.match.params.id,this.state.dialog).then((res) => {

	 		let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso.</span><span data-notify="message">As alterações foram salvas.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
      		this.refs.notificationAlert.notificationAlert(options);
			console.log(res.data);
		 

		}).catch(error => {
 
	        console.log(error);
	        console.log(error.response);

         	if(error.response.status === 400){

	          	let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops, não foi possível cadastrar o curso.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
          		this.refs.notificationAlert.notificationAlert(options);

          		this.setState({erroCourse:''});
          		setTimeout(function(){ this.setState({erroCourse:error.response.data.msg}); }.bind(this), 1);

	        }
	        return false;
		});

	}

	//Para pegar o curso caso esteja editando
	async getCourse(){

		await api.getCourse(this.props.match.params.id).then((res) => {

			this.setState({showCard:true});

			var course = JSON.parse(JSON.stringify(res.data));
			delete course.id;
			delete course.background_file;
			delete course.icon_file;
			delete course.cover_file;
			delete course.updateDate;
			delete course.createDate;

			this.setState({dialog:course});
			
			setTimeout(function(){

				if(res.data.background_file !== undefined){ $('.imgup_background').attr('src',res.data.background_file.urldownload) }
				if(res.data.icon_file !== undefined){ $('.imgup_icon').attr('src',res.data.icon_file.urldownload) }
				if(res.data.cover_file !== undefined){ $('.imgup_cover').attr('src',res.data.cover_file.urldownload) }

				$('input[name="status"][value="'+res.data.status+'"]').prop('checked', true);
				$('input[name="courseType"][value="'+res.data.courseType+'"]').prop('checked', true); 

			}, 5);

		}).catch(error => {

			console.log(error);
	        console.log(error.response);

         	if(error.response.status === 400){

	          	let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Ops, obter o curso.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
          		this.refs.notificationAlert.notificationAlert(options);

          		this.setState({erroCourse:''});
          		setTimeout(function(){ this.setState({erroCourse:error.response.data.msg}); }.bind(this), 1);

	        }

	        if(error.response.status === 404){ this.props.history.push('/'); }

		});

	}

	saveCourse(event){

		event.preventDefault();

		if(this.props.match.params.id === undefined){ this.postCourse(); }else{ this.putCourse(); }

	}

	async componentDidMount(){

		//Caso esteja criando um novo curso
		if(this.props.match.params.id === undefined){ this.setState({showCard:true}); }else{ this.getCourse(); }
		console.log(this.props.match.params);

	}

	 

	render(){

			var title,buttonFooter,simpleHED;

			const handleChange = name => event => {
		        var dialog = this.state.dialog;
		        dialog[name] = event.target.value;
		        this.setState({dialog:dialog});
		    }

		    if(this.props.match.params.id === undefined){

		    	 title = "Novo curso";
		    	 buttonFooter = <Button type="submit" block color="primary" size="lg" ><i class="fas fa-plus"></i> Cadastrar curso</Button>;
		    	 simpleHED = <SimpleHeader name={title} way={[{name:"Cursos",url:"/course"},{name:title,url:this.props.match.url}]} buttons={<React.Fragment></React.Fragment>} />;

		    }else{
				
				 title = "Editando curso";
				 buttonFooter = <Button type="submit" block color="primary" size="lg" ><i class="far fa-save"></i> Salvar alterações</Button>;
				 simpleHED = <SimpleHeader history={this.props.history} name={title} way={[{name:"Cursos",url:"/course"},{name:this.state.dialog.name,url:"/course/"+this.props.match.params.id},{name:title,url:this.props.match.url}]} buttons={<React.Fragment></React.Fragment>} />;
		    
		    }

			return(
		    <React.Fragment>
		        <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
		        
		        {simpleHED}
          	        
          	        <Container className="mt--6" fluid>
		          		<Row className="justify-content-center">
		            		<Col className="card-wrapper" lg="8">
								<Card>
									<CardHeader>
									  <Row><Col xs="6"><h3 className="mb-0">{title}</h3></Col></Row>
									</CardHeader>

									{

					                    (this.state.showCard === false) &&
					                    <center style={{margin:'40px'}}><RingLoader sizeUnit={"px"} size={60} color={'#32325d'} loading={true} /></center>

				                  	}

									{ 

										(this.state.showCard === true) && 

									 

									<CardBody>
										{
											(this.state.erroCourse !== "") &&
											<UncontrolledAlert className="animated bounceIn" color="danger" fade={false}>
									          <span className="alert-inner--icon"><i className="ni ni-like-2" /></span>
									          <span className="alert-inner--text"><strong>Ops!</strong> {this.state.erroCourse}</span>
									        </UncontrolledAlert>
										}
										<form type="post" onSubmit={ (event) => this.saveCourse(event) } >
										<h6 className="heading-small text-muted mb-4">Informações gerais</h6>
					                    <div className="pl-lg-4">
					                      <Row>
					                        <Col lg="12">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Nome do curso:</label>
					                            <Input defaultValue="" onChange={handleChange('name')} value={ this.state.dialog.name } d="input-username" type="text" />
					                          </FormGroup>
					                        </Col>
					                        <Col lg="12">
					                          <FormGroup>
					                            <label className="form-control-label" htmlFor="input-username">Descrição do curso:</label>
					                            <Input defaultValue="" onChange={handleChange('description')} value={ this.state.dialog.description } rows="4" type="textarea" />
					                          </FormGroup>
					                        </Col>
					                        <Col lg="12">
   										    	<label className="form-control-label" style={{marginRight:'10px'}}>Status:</label> 
												<div className="custom-control custom-radio custom-control-inline">
												  <input onChange={handleChange('status')} className="custom-control-input" id="customRadio1" name="status" value="1" type="radio" />
												  <label className="custom-control-label" htmlFor="customRadio1">Disponível para criar turmas.</label>
												</div>
												<div className="custom-control custom-radio custom-control-inline">
												  <input onChange={handleChange('status')} className="custom-control-input" id="customRadio2" name="status" value="0" type="radio" />
												  <label className="custom-control-label" htmlFor="customRadio2">Disponível apenas para ediação.</label>
												</div>
											</Col>

											<Col lg="12">
   										    	<label className="form-control-label" style={{marginRight:'10px'}}>Tipo de curso:</label> 
												<div className="custom-control custom-radio custom-control-inline">
												  <input onChange={handleChange('courseType')} className="custom-control-input" id="customRadio11" name="courseType" value="0" type="radio" />
												  <label className="custom-control-label" htmlFor="customRadio11">WEB</label>
												</div>
												<div className="custom-control custom-radio custom-control-inline">
												  <input onChange={handleChange('courseType')} className="custom-control-input" id="customRadio21" name="courseType" value="1" type="radio" />
												  <label className="custom-control-label" htmlFor="customRadio21">Mobile</label>
												</div>
											</Col>

					                      </Row>
					                    </div>
				                    	<div className="pl-lg-4">
				                    		<hr/>
				                    		<h6 className="heading-small text-muted mb-4">Ícone</h6>
				                      		<Row className="section_file" name="icone" n="0">
				                      			<Col lg="4"><center><img alt="..." className="imgup imgup_icon" src="http://b6.alunoativo.com.br/publico/images/icone_padrao.png"/></center></Col>
				                      			<Col lg="8">
				                      				{
				                      					(this.state.fileInput[0].progress === "") &&
				                      					<div style={{display:"flex"}}>
						                      				<div className="custom-file form-control-sm">
										                        <input onChange={ (event) => { this.changeFileInput(event,'custom_file_0');this.uploadImg('icone'); } } className="custom-file-input" id="custom_file_0" type="file" />
										                        <label className="custom-file-label " htmlFor="custom_file_0">Selecionar arquivo</label>
									                      	</div>
								                      		<Button onClick={ () => this.uploadImg('icone') } color="secondary" type="button" style={{marginLeft:'5px',height:'44px'}}><i class="ni ni-cloud-upload-96"></i> Enviar</Button>
						                          		</div>
				                      				}
					                          		{
					                          			(this.state.fileInput[0].erro !== "") &&
					                          			<h6 className="erroInputBase animated bounceIn">{this.state.fileInput[0].erro}</h6>
					                          		}
					                          		{
					                          			(this.state.fileInput[0].progress !== "") &&
					                          			<div className="progress-wrapper animated fadeIn" style={{paddingTop:'5px'}}>
												          <div className="progress-info">
												            <div className="progress-label"><span>Enviando arquivo</span></div>
												            <div className="progress-percentage"><span>{this.state.fileInput[0].progress}%</span></div>
												          </div>
												          <Progress max="100" value={this.state.fileInput[0].progress} color="default" />
												        </div>
					                          		}
					                          	</Col>
				                      		</Row>
				                      		<hr/>
				                      		<h6 className="heading-small text-muted mb-4">Capa</h6>
				                      		<Row className="section_file" name="capa" n="1">
				                      			<Col lg="4"><center><img alt="..." className="imgup imgup_cover" src="http://b6.alunoativo.com.br/publico/images/icone_padrao.png"/></center></Col>
				                      			<Col lg="8">
				                      				<div style={{display:"flex"}}>
					                      				<div className="custom-file form-control-sm">
									                        <input onChange={ (event) => { this.changeFileInput(event,'custom_file_1');this.uploadImg('capa'); } } className="custom-file-input" id="custom_file_1" type="file" />
									                        <label className="custom-file-label " htmlFor="custom_file_1">Selecionar arquivo</label>
								                      	</div>
							                      		<Button onClick={ () => this.uploadImg('capa') } color="secondary" type="button" style={{marginLeft:'5px',height:'44px'}}><i class="ni ni-cloud-upload-96"></i> Enviar</Button>
					                          		</div>
					                          		{
					                          			(this.state.fileInput[1].erro !== "") &&
					                          			<h6 className="erroInputBase animated bounceIn">{this.state.fileInput[1].erro}</h6>
					                          		}
					                          		{
					                          			(this.state.fileInput[1].progress !== "") &&
					                          			<div className="progress-wrapper animated fadeIn" style={{paddingTop:'5px'}}>
												          <div className="progress-info">
												            <div className="progress-label"><span>Enviando arquivo</span></div>
												            <div className="progress-percentage"><span>{this.state.fileInput[1].progress}%</span></div>
												          </div>
												          <Progress max="100" value={this.state.fileInput[1].progress} color="default" />
												        </div>
					                          		}
					                          	</Col>
				                      		</Row>
				                      		<hr/>
				                      		<h6 className="heading-small text-muted mb-4">Background</h6>
				                      		<Row className="section_file" name="background" n="2">
				                      			<Col lg="4"><center><img alt="..." className="imgup imgup_background" src="http://b6.alunoativo.com.br/publico/images/icone_padrao.png" /></center></Col>
				                      			<Col lg="8">
				                      				<div style={{display:"flex"}}>
					                      				<div className="custom-file form-control-sm">
									                        <input onChange={ (event) => { this.changeFileInput(event,'custom_file_2'); this.uploadImg('background'); } } className="custom-file-input" id="custom_file_2" type="file" />
									                        <label className="custom-file-label " htmlFor="custom_file_2">Selecionar arquivo</label>
								                      	</div>
							                      		<Button onClick={ () => this.uploadImg('background') } color="secondary" type="button" style={{marginLeft:'5px',height:'44px'}}><i class="ni ni-cloud-upload-96"></i> Enviar</Button>
					                          		</div>
					                          		{
					                          			(this.state.fileInput[2].erro !== "") &&
					                          			<h6 className="erroInputBase animated bounceIn">{this.state.fileInput[2].erro}</h6>
					                          		}
					                          		{
					                          			(this.state.fileInput[2].progress !== "") &&
					                          			<div className="progress-wrapper animated fadeIn" style={{paddingTop:'5px'}}>
												          <div className="progress-info">
												            <div className="progress-label"><span>Enviando arquivo</span></div>
												            <div className="progress-percentage"><span>{this.state.fileInput[2].progress}%</span></div>
												          </div>
												          <Progress max="100" value={this.state.fileInput[2].progress} color="default" />
												        </div>
					                          		}
					                          	</Col>
				                      		</Row>
				                      	</div>
				                      	<hr/>
				                      	{buttonFooter}
				                      	</form>
 									</CardBody>
 									}
								</Card>
							</Col>
						</Row>
					</Container>
		    </React.Fragment>);
	}
}
export default NewCourse;