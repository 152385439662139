import React from 'react';
import Main from './views/Main.js'
import api from './Api.js';
import $ from 'jquery';

//import LogRocket from 'logrocket';
//LogRocket.init('ejs9so/csplus-center');
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class App extends React.Component {

  constructor(props){

    super(props);

  
    //Para pegar via cookie

    var session = cookies.get('session');
    var userId = cookies.get('userId'); 

    if(session !== undefined && userId !== undefined){ 
       
      sessionStorage.setItem('session',session);
      sessionStorage.setItem('userId',userId);
  
    }

    
    session = sessionStorage.getItem('session');
    userId = sessionStorage.getItem('userId');

    //caso não tenha, o session é vazio, isso faz o cara ir para página de login
    if(session === null || userId === null){ session = ""; userId = ""; }
 
    this.state = {systemOK:false,userId:userId,session:session,carregouIp:false,darkMode:false,menuMin:false,lessonsMinified:true,languageSelected:"pt-br",languages:[],languageTexts:{}}; 

    this.verificationInitial = this.verificationInitial.bind(this);
    this.getCurrentLanguage = this.getCurrentLanguage.bind(this);
    this.language = this.language.bind(this);
    this.removeSession = this.removeSession.bind(this);
    
     
    window.app = this;

  }
  
  //retorna os testos da linguagem atual
  language(term){ 

    if(term === undefined){ return this.state.languageTexts; }else{ return this.state.languageTexts[term]; }

  }
  
  async verificationInitial(){
       
      var ip = await $.ajax({url: "/servers/"+window.location.hostname+".json",async: true}).done(function(data){return data.responseJSON;})
      .fail(function(){ return false; }).catch(function(){ return false; });

      if(ip === false){ console.log('Erro:01'); return false; }

      if(ip.url === undefined){ console.log('Erro:02'); return false; } 

      var backend = await $.ajax({url: ip.url,async: true}).done(function(data){return data.responseJSON;}).fail(function(){ console.log('falha ao acessar:'+ip.url); return false;}).catch(function(){console.log('falha ao acessar: '+ip.url);return false;});

      if(backend){

        sessionStorage.setItem('backend', ip.url);
        sessionStorage.setItem('verify',ip.verify)
        setTimeout(function(){ this.setState({carregouIp:true}); this.verify(1); }.bind(this), 100);
        return true;

      }else{ console.log('O backend retornou false.'); return false; }

  }

  //Para exibir o preload
  loading(n){}

  //Parar deslogar o usuario do sistema
  removeSession(){

    console.log('removeu tudo');
    sessionStorage.removeItem('session');
    sessionStorage.removeItem('userId');
    delete window.app.state.dataUser;
    delete window.app.state.user;
    this.setState({session:'',userId:''});

  }

  //para verificar no servidor se ele esta logado.
  verify(pass){

    if(this.state.session !== "" && this.state.carregouIp === true){ pass = true; }
    
    if(pass === true  && sessionStorage.getItem('verify') === "true"){
      api.verifyAcess({}).then((res)=>{

        if(this.state.user === undefined){ 

          this.setState({user:res.data}); 
          //LogRocket.identify('csplus_center_'+res.data.id,{name: res.data.name+' '+res.data.lastName,email:res.data.email,subscriptionType:'pro'});
        
        }
        
      }).catch(error=>{
          console.log(error.data);
          console.log(error);
          console.log(error.response);
          if(error.response !== undefined){if(error.response.status !== undefined){if(error.response.status === 401){  this.removeSession();  }}}
      });

    }

    setTimeout(function(){ this.verify(); }.bind(this), 3000);

  }


  //Para carregas as linguagens disponíveis no sistema.
  async getAllLanguage(){
    var language = await api.listLanguages().then((res) => {
      this.setState({languages:res.data})
      return true;
    }).catch(error => {
      console.log(error.data);
      console.log(error);
      console.log(error.response);
      return false;
    });
    return language;
  }


  //para retornar os dados da linguagem atual selecionaand
  getCurrentLanguage(){
    var language;
    for(let i = 0; i < this.state.languages.length; i++){ if(this.state.languages[i].id === this.state.languageSelected){ language = this.state.languages[i]; }}
    return language;
  }

  //para buscar o arquivo da linguagem no sistema
  async getLanguageTexts(nameFile,id){
    console.log(nameFile);
    
    this.setState({languageSelected:id});

    var a = await api.languageText(nameFile).then((res) => {
      this.setState({languageTexts:res.data});
      return true;
    }).catch(error => {
      console.log(error.data);
      console.log(error);
      console.log(error.response);
      return false;
    });
    return a;
  }


  async componentDidMount(){

    //para verificar o backend
    var a = await this.verificationInitial();

    //para pegar as linguagens disponíveis no sistema 
    var b = await this.getAllLanguage();

    //para carregar a linguagem padrão
    var c = await this.getLanguageTexts('pt-br.json','pt-br');

    //caso esteja tudo correto, exibe a página
    if(a === true && b === true && c === true){

      this.setState({systemOK:true});

    }else{

      console.log("Verificação do brancked: "+a);
      console.log("Verificação do languages: "+b);
      console.log("Baixou arquivo de linguagem: "+c);

    }

  }

  render() {

    return (

      <React.Fragment>
        {
          
          (this.state.systemOK === true) &&
          <Main loading={ (n) => this.loading(n) }  setAppState={ (n) => this.setState(n) }  getAppState={ () => this.state } />
        
        }
      </React.Fragment>

    );
  }


}

export default App;