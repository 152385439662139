import React from "react";
import {Card,CardHeader,CardBody,Container,Row,Col,Button,Input,Modal} from "reactstrap";
import SimpleHeader from "./../../../../components/Headers/SimpleHeader.jsx";
import api from "./../../../../Api.js"; 
import NotificationAlert from "react-notification-alert";
import ReactQuill from "react-quill";
import 'rc-time-picker/assets/index.css';
import InputMask from 'react-input-mask';
import {BarLoader} from 'react-spinners';
import {DragDropContext,Droppable,Draggable} from 'react-beautiful-dnd';
import SpeechToText from "./types/SpeechToText.jsx";
import CreateWithPhrase from "./types/CreateWithPhrase.jsx";
import ExerciseCompleteVarious from "./types/ExerciseCompleteVarious.jsx";
import CompleteWithText from "./types/CompleteWithText.jsx";
 
const Swal = require('sweetalert2');

class NewLesson extends React.Component {

  	constructor(props){

  		super(props);
  		this.state = {
			 
			videos:[],
		 	openDialogRemoveLesson:false,

  		 	lessons:[],
			loadLessons:false,

  			loadCourse:false,
  			loadUnit:false,
  			loadQuiz:false,

  			quizzes:[],
  			editLesson:"",

  			dialogLesson:{
  				name:"",
  				icon:"0",
  				type:"1",
  				sequence:"",
  				iframeURL:"",
  				iframeAttributes:"",
  				html:"",
  				unit:"",
  				quiz:"",
  				video:"",
  				estimatedTime:"00:00:00",
  				lockTime:"00:00:00"
  			},

  			tags: [
                { id: "Thailand", text: "Thailand" },
                { id: "India", text: "India" }
             ],
            suggestions: [
                {id: 'USA', text: 'USA' },
                {id: 'Germany', text: 'Germany' },
                {id: 'Austria', text: 'Austria' },
                {id: 'Costa Rica', text: 'Costa Rica' },
                {id: 'Sri Lanka', text: 'Sri Lanka' },
                {id: 'Thailand', text: 'Thailand' }
            ],

            loadImages:false,
            loadVideosFile:false,
            loadAudios:false,

            images:[],
            videosFile:[],
            audios:[]

  		};


        this.blackLessonBackup = JSON.parse(JSON.stringify(this.state.dialogLesson));

  		this.saveLesson = this.saveLesson.bind(this);
  		this.postLesson = this.postLesson.bind(this);
  		this.putLesson = this.putLesson.bind(this);
 		this.goLesson = this.goLesson.bind(this);

 		this.removeLesson = this.removeLesson.bind(this);
        this.resetDialogLesson = this.resetDialogLesson.bind(this);
        this.getCourseQuiz = this.getCourseQuiz.bind(this);
        this.getLessonsPage = this.getLessonsPage.bind(this);
        this.newLesson = this.newLesson.bind(this);

        this.getImages = this.getImages.bind(this);
        this.getvideosFile = this.getvideosFile.bind(this);
        this.getAudios = this.getAudios.bind(this);

  		window.newlesson = this;

  	}


      async getImages(){

        await api.getMedia(this.props.match.params.course,'image').then((res) => { this.setState({images:res.data,loadImages:true}); }).catch(error => {
    
          console.log(error.data);
          console.log(error);
          console.log(error.response);
     
    
        });
    
      }
    
      async getvideosFile(){
    
        await api.getMedia(this.props.match.params.course,'video').then((res) => { this.setState({videosFile:res.data,loadVideosFile:true}); }).catch(error => {
    
          console.log(error.data);
          console.log(error);
          console.log(error.response);
      
        });
    
      }
    
      async getAudios(){
    
        await api.getMedia(this.props.match.params.course,'audio').then((res) => { this.setState({audios:res.data,loadAudios:true}); }).catch(error => {
    
          console.log(error.data);
          console.log(error);
          console.log(error.response);
      
        });
    
      }
      
 	/*handleDelete(i) {
        const { tags } = this.state;
        this.setState({ tags: tags.filter((tag, index) => index !== i), });
    }
 
    handleAddition(tag) {
        this.setState(state => ({ tags: [...state.tags, tag] }));
    }
 
    handleDrag(tag, currPos, newPos) {
        const tags = [...this.state.tags];
        const newTags = tags.slice();
 
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
 
        // re-render
        this.setState({ tags: newTags });
    }*/

    resetDialogLesson(){

    	this.setState({dialogLesson:{
            name:"",
            icon:"0",
            type:"1",
            sequence:"",
            iframeURL:"",
            iframeAttributes:"",
            html:"",
            unit:"",
            quiz:"",
            video:"",
            estimatedTime:"00:00:00",
            lockTime:"00:00:00"
        }});

    }

    //Para pegar os quizzes cadastrado no curso
	async getCourseQuiz(){

	    var course = await api.getQuizCourse(this.props.match.params.course).then((res) => {

            this.setState({quizzes:res.data,loadQuizzes:true});
            return true;

	    }).catch(error => {

            console.log(error.data);
            console.log(error);
            console.log(error.response);
            return false;

		});
		
		return course;

	}

	//Para pegar os vídeos cadastrados no curso
	async getCourseVideo(){

	    var videos = await api.getVideoCourse(this.props.match.params.course).then((res) => {

            this.setState({videos:res.data,loadVideos:true});
            console.log(res.data);
            return true;

	    }).catch(error => {

            console.log(error.data);
            console.log(error);
            console.log(error.response);
            
            return false;
		  
	    });

		return videos;

	}

  	//Para pegar todas as lessons da unit
    /*async getLessons(){

		var unit = await api.getLessonUnit(this.props.match.params.unit).then((res) => {
			 
        	this.setState({lessons:res.data,loadLesson:true});
			return true;

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);
			return false;

  		});

		return unit;

	}*/
  
  	//Para pegar os dados do curso
  	async getCourse(){

  		await api.getCourse(this.props.match.params.course ).then((res) => {
            
            this.setState({course:res.data,loadCourse:true});
            console.log(res.data);

	  	}).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);

	    	if(error.response.status === 404){
                
                //this.props.history.push('/');
        
            }

	  	});

	}

  	//Para pegar as units
  	async getUnit(){

		await api.getUnit(this.props.match.params.unit).then((res) => {
            
        	this.setState({unit:res.data,loadUnit:true});
        	console.log(res.data);
        	return true;

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);
        	return false;
  		});
 
	}
 
	//para cadastrar a lesson
	async postLesson(){

		var data = JSON.parse(JSON.stringify(this.state.dialogLesson));
		data.unit = this.props.match.params.unit;
        data.page = this.props.match.params.page;
		delete data.lessonGroup;
		
		if(data.videoHelper === null){

			data.videoHelper = "";

		}

		await api.postLesson(data).then((res) => {
            
            this.getLessonsPage();
        	let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso</span><span data-notify="message">A lesson foi cadastrada.</span></div>),type: 'success',icon: "fas fa-check",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);
            this.goLesson(res.data.lesson.id);
			//this.props.history.push('/course/'+this.state.course.id+'/unit/'+this.state.unit.id+'/lesson/'+res.data.lesson.id);

  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

        	if(error.response.status === 400){
   
          		let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível cadastrar a lesson.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
  				this.refs.notificationAlert.notificationAlert(options);

          	}

          	return false;

  		}); 

	}

	removeLesson(id){
        
        Swal.fire({
            title: 'Tem certeza?',
            text: " ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, remover!',
            cancelButtonText:"Não, cancelar"
        }).then((result) => {

            if(result.value){
                
                api.deleteLesson(id).then((res) => {
                    
                    let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">A atividade foi removida.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
                    this.refs.notificationAlert.notificationAlert(options);

                    this.getLessonsPage();

                    console.log(this.state.editorlesson);
                    console.log(id);

                    if(parseInt(this.state.editorlesson) === id){

                        this.setState({loadLesson:false});
                        this.props.history.push('/course/'+this.props.match.params.course+'/unit/'+this.props.match.params.unit+'/managerchapter/'+this.props.match.params.page);

                    }
        
                }).catch(error => {
        
                    console.log(error.data);
                    console.log(error);
                    console.log(error.response);
        
                });

            }

        });
        
	}

	//Para atualizar a lesson
	async putLesson(){

		var data = JSON.parse(JSON.stringify(this.state.dialogLesson));
		delete data.unit;
		delete data.id;
		delete data.dataVideo;
		delete data.lessonGroup;

		if(data.videoHelper === null){

			data.videoHelper = "";

		}

		await api.putLesson(this.props.match.params.lesson,data).then(async (res) => {
            
            //Para pegar todas as lessons
            await this.getLessonsPage();
        	let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso</span><span data-notify="message">As alterações foram salvas.</span></div>),type: 'success',icon: "fas fa-check",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);


  		}).catch(error => {

        	console.log(error.data);
        	console.log(error);
        	console.log(error.response);

        	if(error.response.status === 400){
   
          		let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi salvar as alterações.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
  				this.refs.notificationAlert.notificationAlert(options);

          	}

          	return false;

  		}); 

	}

	//Para pegar a lesson caso esteja editando
	async getLesson(id){

        this.setState({loadLesson:false});
		await api.getLesson(id).then((res) => {
            
         	var lesson = res.data;
         	delete lesson.createDate;
			delete lesson.updateDate;
			 
			if(res.data.lockTime === null || res.data.lockTime === ""){ res.data.lockTime = "00:00:00"; }
			if(res.data.estimatedTime === null || res.data.estimatedTime === ""){ res.data.estimatedTime = "00:00:00"; }

         	this.setState({dialogLesson:lesson,loadLesson:true});

	  	}).catch(error => {

	        console.log(error.data); 
	        console.log(error);
			console.log(error.response);
			if(error.response !== undefined){

				if(error.response.status === 404){

					//this.props.history.push('/');

				}

			}

	  	});

	}

	//Caso clique o lapis para editar uma lesson na navegação rápida
	goLesson(id){

		this.props.history.push('/course/'+this.props.match.params.course+'/unit/'+this.props.match.params.unit+'/managerchapter/'+this.props.match.params.page+'/'+id);
        if(this.state.editorlesson !== this.props.match.params.lesson){

            if(this.props.match.params.lesson !== undefined){

                this.state.editorlesson = this.props.match.params.lesson;

            }

        }

		this.getLesson(id);

	}

	//Para saver se deve cadastrar ou atualizar
	saveLesson(event){

        if(event !== undefined){ event.preventDefault(); }
    	if(this.props.match.params.lesson === "new"){ 
            
            this.postLesson();
        
        }else{ 
            
            this.putLesson();
        
        }

	}

    async getLessonsPage(){

        api.getLessonsPage(this.props.match.params.page).then(async (res) => {

            console.log(res.data);
            this.setState({lessons:res.data,loadLessons:true})

        }).catch((error) => {

            console.log(error);
            console.log(error.response);

        });

    }

	async componentDidMount(){
        
        await this.getLessonsPage();
		await this.getUnit();
		await this.getCourse();
		
		await this.getCourseQuiz();
		await this.getCourseVideo();


        this.getImages();
        this.getvideosFile();
        this.getAudios();

		//Caso esteja editando
		if(this.props.match.params.lesson === "new"){

            this.state.editorlesson = "new";
            this.newLesson();

        }else{
            
            this.state.editorlesson = this.props.match.params.lesson;
			await this.getLesson(this.props.match.params.lesson);

		}

		//window.addEventListener("resize", this.updateDimensions);

	}
 
    componentDidUpdate(){

        console.log('deu update');

        var lesson = this.props.match.params.lesson;
        console.log('lesson: '+lesson);

        if(lesson !== undefined){

            if(this.state.editorlesson !== lesson){

                console.log('this.state.editorlesson: '+this.state.editorlesson);

                this.state.editorlesson = lesson;
                if(lesson !== "new"){

                    this.getLesson(this.props.match.params.lesson);

                }

                if(lesson === "new"){

                    this.newLesson();

                }

            }

        }

        if(lesson === undefined){

            if(this.state.editorlesson !== lesson){

                this.state.editorlesson = lesson;
                this.setState({loadLesson:false});

            }

        }
        
    }


	componentWillMount(){

        //this.updateDimensions();

	}

	componentWillUnmount(){

		//window.removeEventListener("resize", this.updateDimensions);

	}
	
 
 

    handleChange(value,name){

  		console.log(value && value.format('mm:ss'));

	 	var dialogLesson = this.state.dialogLesson;
        dialogLesson[name] = '00:'+value.format('mm:ss');
        this.setState({dialogLesson:dialogLesson});

  	}
 
	//Para setar a dimenção da navegação rápida
	/*updateDimensions(){
	 
		var h = $(window).height();

		h = h - 66 - 78 - 79 - 10;
		$("#listLessons").css('height',h+'px');
		$("#listLessons").css('overflow-y','auto');
		$("#listLessons").css('overflow-x','hidden');
		$("#listLessons").css('padding','15px 0px 0px 20px');

	}*/

    handleChange = name => event => {

        var dialogLesson = this.state.dialogLesson;
        dialogLesson[name] = event.target.value;
        
        if(name === "type"){
            
            //texto
            if(event.target.value === "0" || event.target.value === "1"){ dialogLesson.icon = 0; }

            //quiz
            if(event.target.value === "3"){ dialogLesson.icon = 3; }

            //video
            if(event.target.value === "5"){ dialogLesson.icon = 1; }

        }

        this.setState({dialogLesson:dialogLesson});
    
    };

    newLesson(){

        if(this.state.unit === undefined){ return false; }
        if(this.state.course === undefined){ return false; }

        this.props.history.push('/course/'+this.state.course.id+'/unit/'+this.state.unit.id+'/managerchapter/'+this.props.match.params.page+'/new');
        this.setState({dialogLesson:JSON.parse(JSON.stringify(this.blackLessonBackup)),loadLesson:true});

    }

  	render(){

            var txtButton = (this.props.match.params.lesson === 'new' ? 'Cadastrando atividade':'Salvar alterações');
            var newLesson = (() => {

                this.newLesson();    

            });

            return(
                <React.Fragment>
                <div>
                    </div>

                        <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
                        {
                            (this.state.loadCourse === true && this.state.loadUnit === true) &&
                            <SimpleHeader name={'teste'} way={[
                                {name:"Cursos",url:"/course"},
                                {name:this.state.course.name,url:'/course/'+this.state.course.id},
                                {name:this.state.unit.name,url:'/course/'+this.state.course.id+'/unit/'+this.state.unit.id+'/managerchapter'},
                                {name:'Page',url:'/'},
                            ]} 
                            buttons={
                            <React.Fragment>
                                <button onClick={() => {

                                    newLesson();

                                }} color="primary" type="submit" className="btn btn-sm btn-secondary">Nova atividade</button>
                            </React.Fragment>
                            } />

                        }
                        <Container className="mt--6" fluid>
                            <Row>
                                <Col lg="9">

                                    {

                                        (this.props.match.params.lesson === undefined) &&
                                        <React.Fragment>
                                            <Card>
                                                <CardBody className="bg-secondary" style={{borderRadius:'10px',textAlign:'center'}}>
                                                    <h4>Você deve selecionar uma atividade, ou criar uma nova.</h4>
                                                </CardBody>
                                            </Card>
                                        </React.Fragment>

                                    }
                                    {

                                        (this.state.loadLesson === true) &&
                                        <React.Fragment>
                                            <Card>
                                                <CardHeader className="bg-transparent">
                                                <Row><Col xs="3"><h3 className="mb-0">{(this.props.match.params.lesson === 'new' ? 'Cadastrando atividade':'Editando atividade')}</h3></Col></Row>
                                                </CardHeader>
                                                <CardBody className="bg-secondary">
                                                    <Row>
                                                        <Col md="3">
                                                            <div><h5>Nome da lesson:</h5><Input onChange={this.handleChange('name')} value={ this.state.dialogLesson.name } className="form-control-alternative" type="text" /></div>
                                                        </Col>
                                                        <Col md="3">
                                                            <div><h5>Tmp. de bloqueio:</h5>
                                                            <InputMask className="form-control form-control-alternative"  onChange={ this.handleChange('lockTime') } value={ this.state.dialogLesson.lockTime } mask="99:99:99" maskChar=" " onFocus={() => this.setState({ focusedNascimento: true }) } onBlur={() => this.setState({ focusedNascimento: false }) }/>
                                                        </div>
                                                        </Col>
                                                        <Col md="3">
                                                            <div><h5>Tempo estimado:</h5>
                                                                <InputMask className="form-control form-control-alternative" onChange={ this.handleChange('estimatedTime') } value={ this.state.dialogLesson.estimatedTime } mask="99:99:99" maskChar=" " onFocus={() => this.setState({ focusedNascimento: true }) } onBlur={() => this.setState({ focusedNascimento: false }) }/>
                                                            </div>
                                                        </Col>
                                                        <Col md="3">
                                                            <div><h5>Ícone:</h5>
                                                                <Input onChange={this.handleChange('icon')} value={ this.state.dialogLesson.icon } className="form-control-alternative" type="select">
                                                                    <option value="0">Texto</option>
                                                                    <option value="1">Vídeo</option>
                                                                    <option value="2">Audio</option>
                                                                    <option value="3">Quiz</option>
                                                                    <option value="4">Gamepad</option>
                                                                </Input>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row style={{marginTop:'10px'}}>
                                                        <Col md="2">
                                                            <div>
                                                                <h5>Tipo:</h5>
                                                                <Input onChange={this.handleChange('type')} value={ this.state.dialogLesson.type } className="form-control-alternative" type="select">
                                                                    <option selected disabled value="disabled"></option>
                                                                    <option value="0">Texto livre com editor</option>
                                                                    <option value="1">Texto livre sem editor</option>
                                                                    <option value="2">Iframe</option>
                                                                    <option value="3">Quiz</option>
                                                                    <option value="5">Vídeo</option>
                                                                    <option value="6">create_with_phrase</option>
                                                                    <option value="7">exercise_complete_various</option>
                                                                    <option value="8">table_speech_on_text</option>
                                                                    <option value="9">complete_with_text</option>
                                                                </Input>
                                                            </div>
                                                        </Col>
                                                        <Col md="3">
                                                            <div>
                                                                <h5>Vídeo de ajuda:</h5>
                                                                <Input onChange={this.handleChange('videoHelper')} value={ this.state.dialogLesson.videoHelper } className="form-control-alternative" type="select">
                                                                    <option value=""></option>
                                                                    {

                                                                        /*this.state.videos.map((value,index) => {

                                                                            return(<option value={value.id}>{value.name}</option>)

                                                                        })*/

                                                                    } 
                                                                </Input>
                                                            </div>
                                                        </Col>
                                                        <Col md="3">
                                                            <div>
                                                                <h5>Áudio de ajuda:</h5>
                                                                <Input onChange={this.handleChange('audioHelper')} value={ this.state.dialogLesson.audioHelper } className="form-control-alternative" type="select">
                                                                    <option value=""></option>
                                                                    {

                                                                        this.state.audios.map((value,index) => {

                                                                            return(<option value={value.id}>{value.name}</option>)

                                                                        })

                                                                    } 
                                                                </Input>
                                                            </div>
                                                        </Col>
                                                        <Col md="3">
                                                            <h5>Descrição:</h5>
                                                            <Input onChange={this.handleChange('description')} value={ this.state.dialogLesson.description } style={{minHeight:'20px',height:'46px'}} className="form-control-alternative" type="textarea"></Input>
                                                        </Col> 
                                                    </Row>
                                                    <Row style={{marginTop:'10px'}}>
                                                        {

                                                            (parseInt(this.state.dialogLesson.type) === 6) &&
                                                            <React.Fragment>
                                                                <div className="" style={{display:'flex',justifyContent:'center',width:'100%'}}> 
                                                                    <div className="border-0 mb-0 card" style={{padding:'20px',minWidth:'920px'}}>
                                                                         <CreateWithPhrase state={this.state} setState={(data) => this.setState(data) } />
                                                                        <center><Button color="primary" onClick={ () => this.saveLesson(undefined) } style={{marginTop:"10px"}}>{txtButton}</Button></center>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>

                                                        }

                                                        {

                                                            (parseInt(this.state.dialogLesson.type) === 7) &&
                                                            <React.Fragment>
                                                                <div className="" style={{display:'flex',justifyContent:'center',width:'100%'}}> 
                                                                    <div className="border-0 mb-0 card" style={{padding:'20px',width:'100%'}}>
                                                                        <ExerciseCompleteVarious state={this.state} setState={(data) => this.setState(data) } />
                                                                        <center><Button color="primary" onClick={ () => this.saveLesson(undefined) } style={{marginTop:"10px"}}>{txtButton}</Button></center>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>

                                                        }
                                                        
                                                        {

                                                            (parseInt(this.state.dialogLesson.type) === 8) &&
                                                            <React.Fragment>
                                                                <div className="" style={{display:'flex',justifyContent:'center',width:'100%'}}> 
                                                                    <div className="border-0 mb-0 card" style={{padding:'20px',minWidth:'920px'}}>
                                                                        <SpeechToText state={this.state} setState={(data) => this.setState(data) } />
                                                                        <center><Button color="primary" onClick={ () => this.saveLesson(undefined) } style={{marginTop:"10px"}}>{txtButton}</Button></center>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>

                                                        }
 
                                                        {

                                                            (parseInt(this.state.dialogLesson.type) === 9) &&
                                                            <React.Fragment>
                                                                <div className="" style={{display:'flex',justifyContent:'center',width:'100%'}}> 
                                                                    <div className="border-0 mb-0 card" style={{padding:'20px',width:'100%'}}>
                                                                        <CompleteWithText state={this.state} setState={(data) => this.setState(data) } />
                                                                        <center><Button color="primary" onClick={ () => this.saveLesson(undefined) } style={{marginTop:"10px"}}>{txtButton}</Button></center>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>

                                                        }

                                                        {
                                                            //Texto com editor
                                                            (parseInt(this.state.dialogLesson.type) === 0) &&
                                                            <React.Fragment>
                                                                <form onSubmit={ (event) => this.saveLesson(event) } style={{width:'100%'}}>
                                                                    <Col md="12" lg={12}>
                                                                        <h5>Texto:</h5>
                                                                        <ReactQuill onChange={ (a) => { var dialogLesson = this.state.dialogLesson; dialogLesson.html = a; this.setState({dialogLesson:dialogLesson}) } } value={ this.state.dialogLesson.html } style={{width:"100%"}} theme="snow" 
                                                                        
                                                                                modules={{toolbar: [
                                                                                    [{font:[]},{size:[]}],
                                                                                    ['bold','italic','underline','strike'],
                                                                                    [{color:[]},{'background':[]}],
                                                                                    [{script:'super'},{'script':'sub'}],
                                                                                    [{header:'1'},{header:'2'},'blockquote','code-block'],
                                                                                    [{list:'ordered'},{list:'bullet'},{indent:'-1'},{indent:'+1'}],
                                                                                    ['direction',{align:[]}],
                                                                                    ['link','image','video','formula'],
                                                                                    ['clean']
                                                                                ] 
                                                                            
                                                                            }}
                                                                        />
                                                                        <Button color="primary" type="submit" style={{float:'right',marginTop:"10px"}}>{txtButton}</Button>
                                                                    </Col>
                                                                </form>
                                                            </React.Fragment>

                                                        }
                                                        {	//Texto sem editore
                                                            (parseInt(this.state.dialogLesson.type) === 1) &&
                                                            <React.Fragment>
                                                                <Col md="12">
                                                                    <form onSubmit={ (event) => this.saveLesson(event) } >
                                                                        <h5>Texto:</h5>
                                                                        <Input onChange={this.handleChange('html')} value={ this.state.dialogLesson.html } className="form-control-alternative" type="textarea"></Input>
                                                                        <br/>
                                                                        <Button color="primary" type="submit" style={{float:'right'}}>{txtButton}</Button>
                                                                    </form>
                                                                </Col>
                                                            </React.Fragment>
                                                        }
                                                        {	
                                                            //Iframe
                                                            (parseInt(this.state.dialogLesson.type) === 2) &&
                                                            <React.Fragment>

                                                                <Container className="" style={{marginTop:'25px'}}>
                                                                    <form onSubmit={ (event) => this.saveLesson(event) } >
                                                                        <center><h3>Configuração do iframe</h3></center>
                                                                        <row>
                                                                            
                                                                            Você pode passar as variaveis abaixo caso precise fazer alguma integração.<br/>

                                                                            <br/><br/>Variáveis da licença:<br/>

                                                                            <strong>[[url_engine_backend]] </strong>
                                                                            <strong>[[url_engine_frontend]] </strong>
                                                                            <strong>[[url_front_reactengine]] </strong>
                                                                            <strong>[[url_back_reactengine]] </strong>
                                                                            <strong>[[course_id]] </strong>
                                                                            <strong>[[license]] </strong>
                                                                            <strong>[[url_scratch]] </strong>

                                                                            <strong>[[url_back_ide_html]] </strong>
                                                                            <strong>[[url_front_ide_html]] </strong>

                                                                            <br/><br/>Variáveis do usuário:<br/>
                                                                            <strong>[[id_user]] </strong>
                                                                            <strong>[[user_name]] </strong>
                                                                            <strong>[[user_lastName]] </strong>
                                                                            <strong>[[user_username]] </strong>
                                                                            <strong>[[user_birthDate_type_datedb]] </strong>
                                                                            <strong>[[user_birthDate_type_default]] </strong>

                                                                            <br/><br/>Variáveis do curso:<br/>
                                                                            <strong>[[course_id]] </strong>
                                                                            <strong>[[course_name]] </strong>
                                                                            <strong>[[course_description]] </strong>
            
                                                                            <br/><br/>
                                                                            Variáveis da turma:
                                                                            <br/>
                                                                            <strong>[[id_classRoom]] </strong>
                                                                            <strong>[[classRoom_name]] </strong>
                                                                            
                                                                            <br/><br/>
                                                                            Variáveis do professor da turma:
                                                                            <br/>
                                                                            <strong>[[teacher_name]] </strong>
                                                                            <strong>[[teacher_lastName]] </strong>
                                                                            <strong>[[teacher_email]] </strong>
                                                                            <strong>[[teacher_username]] </strong>

                                                                        </row>
                                                                        <Row>
                                                                            <Col md="6">
                                                                                <br/>
                                                                                <div>
                                                                                    <h5>Informe a URL (src):</h5>
                                                                                    <Input onChange={this.handleChange('iframeURL')} value={ this.state.dialogLesson.iframeURL } className="form-control-alternative" type="text" />
                                                                                </div>
                                                                            </Col>
                                                                    
                                                                            <Col md="6">
                                                                                <br/>
                                                                                <div>
                                                                                    <h5>Atributos:</h5>
                                                                                    <Input onChange={this.handleChange('iframeAttributes')} value={ this.state.dialogLesson.iframeAttributes } className="form-control-alternative" type="text" />
                                                                                </div>
                                                                                <p className="h6">Ex: class="frameEx" style="" </p>
                                                                            </Col>
                                                                        </Row>
                                                                    
                                                                        <Row>
                                                                            <Col md="12">
                                                                                <br/>
                                                                                <Button color="primary" type="submit" style={{width:'100%'}}>{txtButton}</Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </form>  
                                                                </Container>
                                                            </React.Fragment>
                                                        }{	
                                                            //Quiz
                                                            (parseInt(this.state.dialogLesson.type) === 3) &&
                                                            <React.Fragment>
                                                                <Container className="" style={{marginTop:'25px'}}>
                                                                    <Row className="justify-content-center">
                                                                        <Col lg="5" md="7">
                                                                            <Card className="border-0 mb-0">
                                                                                <CardBody>
                                                                                    <form onSubmit={ (event) => this.saveLesson(event) } >
                                                                                        <center><h3>Configuração do quiz</h3></center>
                                                                                        <Row>
                                                                                            <Col md="12">
                                                                                                <br/>
                                                                                                <div>
                                                                                                    <h5>Escolha um quiz:</h5>
                                                                                                    <Input onChange={this.handleChange('quiz')} value={ this.state.dialogLesson.quiz } className="form-control-alternative" type="select" >
                                                                                                        <option value=""></option> 
                                                                                                        {

                                                                                                            this.state.quizzes.map((value,index) => {

                                                                                                                return(<option value={value.id}>{value.name}</option>)

                                                                                                            })

                                                                                                        }
                                                                                                    </Input>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col md="12">
                                                                                            <br/>
                                                                                            <Button color="primary" type="submit" style={{width:'100%'}}>{txtButton}</Button>
                                                                                            </Col>	 
                                                                                        </Row>
                                                                                    </form>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>
                                                                </Container>
                                                            </React.Fragment>
                                                        }
                                                        {	
                                                            //Quiz
                                                            (parseInt(this.state.dialogLesson.type) === 5) &&
                                                            <React.Fragment>
                                                                <Container className="" style={{marginTop:'25px'}}>
                                                                    <Row className="justify-content-center">
                                                                        <Col lg="5" md="7">
                                                                            <Card className="border-0 mb-0">
                                                                                <CardBody>
                                                                                    <form onSubmit={ (event) => this.saveLesson(event) } >
                                                                                        <center><h3>Configuração do vídeo</h3></center>
                                                                                        <Row>
                                                                                            <Col md="12">
                                                                                                <br/>
                                                                                                <div>
                                                                                                    <h5>Escolha um vídeo:</h5>
                                                                                                    <Input onChange={this.handleChange('video')} value={ this.state.dialogLesson.video } className="form-control-alternative" type="select" >
                                                                                                        <option value=""></option>  
                                                                                                        {

                                                                                                            this.state.videos.map((value,index) => {

                                                                                                                return(<option value={value.id}>{value.name}</option>)

                                                                                                            })

                                                                                                        }
                                                                                                    </Input>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col md="12">
                                                                                            <br/>
                                                                                            <Button color="primary" type="submit" style={{width:'100%'}}>{txtButton}</Button>
                                                                                            </Col>	 
                                                                                        </Row>
                                                                                    </form>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>
                                                                </Container>
                                                            </React.Fragment>
                                                        }
                                                        {	
                                                            //Questão discursiva
                                                            (parseInt(this.state.dialogLesson.type) === 4) &&
                                                            <React.Fragment>
                                                            </React.Fragment>
                                                        }
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </React.Fragment>

                                    }

                                     
                                </Col>
                                <Col lg="3">
                                    <Card>
                                        <CardHeader className="bg-transparent">
                                            <Row>
                                                <Col md="12"><h3 className="mb-0">Navegação rápida</h3></Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody className="bg-secondary" id="listLessons">
                                            {

                                                (this.state.loadLessons === false) &&
                                                <React.Fragment>
                                                    <div style={{display:'flex',justifyContent:'center'}}><BarLoader size={''} /></div>
                                                </React.Fragment>

                                            }
                                            {
                                                (this.state.loadLessons === true) &&
                                                <React.Fragment>
                                                    {
                                                        (this.state.lessons.length === 0) &&
                                                        <center><h5>Nenhuma lesson foi encontrada.</h5></center>
                                                    }	
                                                </React.Fragment>
                                            }{
                                                (this.state.loadLessons === true) &&
                                                <React.Fragment>

                                                {/*<DragDropContext onDragEnd={(e) => this.onDragEnd2(e)}>
                                                    <Droppable droppableId={"drop_colum_dd2"} direction="vertical" type="cards">
                                                    {

                                                        (provided,snapshot) => (<div style={{ }} ref={provided.innerRef} {...provided.droppableProps}>
 
                                                            {

                                                                this.state.lessons.map((value,index) => {

                                                                    return(
                                                                        <Draggable key={value.id} draggableId={'lesson_'+value.id}  index={index}>
                                                                            {
                                                                                (provided,snapshot) => (
                                                                                
                                                                                <div {...provided.dragHandleProps} className={''} style={{display:"flex",postion:'relative',alignItems:'center'}}>
                                                                                    <span onClick={ () => this.goLesson(value.id) } className="timeline-step badge-info" style={{cursor:"pointer",left:'10px',position:'unset'}}><i className={window.iconsLesson[value.icon]} />   </span>
                                                                                    <i className="fas fa-arrows-alt"  style={{fontSize: '16px',position:'relative',left:'-7px'}}></i>
                                                                                    <input onClick={ () => this.goLesson(value.id) } className="input_edit_lesson" readOnly value={value.name} />
                                                                                    <div style={{position:'absolute',right:'20px'}}>
                                                                                        <i onClick={ () => this.removeLesson(value.id) } style={{cursor:'pointer'}} className="far fa-trash-alt"></i>
                                                                                        <i onClick={ () => this.goLesson(value.id) }  style={{cursor:'pointer',marginLeft:'7px'}} className="fas fa-pencil-alt"></i>  
                                                                                    </div>
                                                                                </div>)

                                                                            }
                                                                        </Draggable>)

                                                                })

                                                            }

                                                        </div>)
                                                
                                                    }
                                                    </Droppable>
                                                </DragDropContext>*/}


                                                            <DragDropContext onDragEnd={(e) => { console.log(e); }}>
                                                                <Droppable droppableId={"drop_colum_teste"} direction="vertical" type="cards">
                                                                    {

                                                                        (provided3) => (
                                                                        <div style={{ }} ref={provided3.innerRef} {...provided3.droppableProps} >
                                                                            {

                                                                                this.state.lessons.map((value2,index2) => {
                                                                                    
                                                                                    return(
                                                                                        <Draggable key={value2.id} draggableId={'opportunity_'+value2.id}  index={index2}>
                                                                                        {
                                                                                            (provided3) => (
                                                                                            <div {...provided3.draggableProps} ref={provided3.innerRef} className="chapter">
                                                                                                <div style={{display:'flex',alignItems:'center'}}>
                                                                                                    <div><i style={{marginRight:'10px'}} className={window.iconsLesson[value2.icon]} /></div>
                                                                                                    <div><i {...provided3.dragHandleProps} className="fas fa-arrows-alt chatp_mover"></i></div>
                                                                                                    <input onClick={ () => this.goLesson(value2.id) } className="input_edit_lesson" readOnly value={value2.name} />
                                                                                                    <div style={{position:'absolute',right:'5px'}}>
                                                                                                        <i onClick={ () => this.removeLesson(value2.id) } style={{cursor:'pointer'}} className="far fa-trash-alt"></i>
                                                                                                        <i onClick={ () => this.goLesson(value2.id) }  style={{cursor:'pointer',marginLeft:'7px'}} className="fas fa-pencil-alt"></i>  
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>)
                                                                                        }
                                                                                        </Draggable>
                                                                                    )

                                                                                })

                                                                            }
                                                                        </div>)

                                                                    }
                                                                </Droppable>
                                                            </DragDropContext>

                                                
                                                </React.Fragment>
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                        </Row>
                        </Container>
                </React.Fragment>
            )
  	}
}
export default NewLesson;