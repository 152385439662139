import React from "react";
import api from "./../../Api.js";
import classnames from "classnames";
import $ from 'jquery';
import {Alert,Modal,CardBody,FormGroup,InputGroupAddon,Form,Input,InputGroupText,InputGroup,Button,Card,CardHeader,Table,Container,Row,Col,UncontrolledTooltip} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";

class Tables extends React.Component {

  	constructor(props){

  		super(props);
  		this.state = {

  			permitions:[],
  			groups:{school:[],center:[]},
  			groupError:"",
  			openModalNewGroup:false,dialogGroup:{name:"",groupName:""},erroNewGroup:"",textDialog:"Cadastrar grupo",
			openModalEditPermition:false,
			permChecks:[],

  		};

  		this.getAccessLevelGroup = this.getAccessLevelGroup.bind(this);
  		this.getAllPermission = this.getAllPermission.bind(this);
  		this.newGroup = this.newGroup.bind(this);
  		this.postGroup = this.postGroup.bind(this);
  		this.putGroup = this.putGroup.bind(this);
  		this.getGroups = this.getGroups.bind(this);
  		this.editGroup = this.editGroup.bind(this);
  		this.editPermission = this.editPermission.bind(this);
  		this.savePermition = this.savePermition.bind(this);

    	window.permission = this;

  	}

	//para os niveis de acesso de um grupo
	async getAccessLevelGroup(grupo){

	  await api.getAccessLevelGroup(grupo).then((res) => {
	    
	    var groups = this.state.groups;
	    groups[grupo] = res.data;
	    this.setState({groups:groups});

	  }).catch(error => {

	    console.log(error.data);
	    console.log(error);
	    console.log(error.response);

	  });

	}

	//Para pegar as permissões
	async getAllPermission(){

	  await api.getAllPermission().then((res) => {
	    
	    this.setState({permitions:res.data});

	  }).catch(error => {

	    console.log(error.data);
	    console.log(error);
	    console.log(error.response);

	  });

	}
 

	//para buscar as linguagens disponíveis
	async listLanguages(){

	  await api.listLanguages().then((res) => {
	    
	    this.setState({language:res.data});

	  }).catch(error => {

	    console.log(error.data);
	    console.log(error);
	    console.log(error.response);

	  });

	}

	//para atualizar os dados do grupo no sistema.
	async putGroup(){
 		
 		var data = JSON.parse(JSON.stringify(this.state.dialogGroup));
 		delete data.groupName;

		await api.putGroupPermission(this.state.groupEdit,data).then((res) => {

			this.setState({openModalNewGroup:false});

			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">As alterações foram salvas com sucesso.</span><span data-notify="message">Informe ao usuário para relogar no sistema.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);

			this.getGroups();

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);

			this.setState({loading:false});

				if(error.response.status === 400){
				this.setState({groupError:""});
				this.setState({groupError:error.response.data.msg});

			}

		});

	}

	//para cadastrar o grupo no banco de dados
	async postGroup(){

		await api.postGroupPermission(this.state.dialogGroup).then((res) => {
 			
 			this.setState({openModalNewGroup:false});

			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">O grupo cadastrado com sucesso.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);

			this.getGroups();

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);

			this.setState({loading:false});

	 		if(error.response.status === 400){

		  		this.setState({groupError:""});
	  			this.setState({groupError:error.response.data.msg});

			}

		});

	}
 	
	//Para editar as permissões de um grupo
	editPermission(id,groupName){
 
		this.setState({groupEdit:id,openModalEditPermition:true,showPermitionGrupo:groupName});
		

		setTimeout(function(){
		
			for (var i = 0; i < this.state.groups[groupName].length; i++) {
				if(parseInt(this.state.groups[groupName][i].id) === parseInt(id)){

					console.log(this.state.groups[groupName][i]);
					this.setState({editPermissionName:this.state.groups[groupName][i].name});
					
					var permissions = this.state.groups[groupName][i].permissions;
					this.setState({permChecks:permissions});
			 

				}
			}

		}.bind(this), 200);
		
	}

	/*checkBox(permissions){

		for(var i2 = 0; i2 < permissions.length; i2++){

			console.log("#input_permition_"+permissions[i2]);
			window.$("#input_permition_"+permissions[i2]).prop('checked',true);
		
		}

	}*/

	//para salvar as permissões de um grupo de acesso
	async savePermition(event){

		event.preventDefault();

		var permissions = [];
		$(".inputPermission:checked").each(function(){ permissions.push($(this).val()); });

		//Para salvar as permissões no grupo
		await api.savePermissionGroup(this.state.groupEdit,permissions).then((res) => {
 			
 			this.setState({openModalEditPermition:false});

			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">As alterações foram salvas.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);

			this.getGroups();

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);

			this.setState({loading:false});

	 		if(error.response.status === 400){

		  		this.setState({groupError:""});
	  			this.setState({groupError:error.response.data.msg});

			}

		});

	}

 	//Para cadastrar um novo grupo
	newGroup(groupName){

		this.setState({groupEdit:"",openModalNewGroup:true,dialogGroup:{name:"",groupName:groupName},erroNewGroup:"",textDialog:"Cadastrar grupo",groupError:""});

	}

	editGroup(groupName,id){

		for (var i = 0; i < this.state.groups[groupName].length; i++){
			if(parseInt(this.state.groups[groupName][i].id) === parseInt(id)){

				this.setState({groupEdit:this.state.groups[groupName][i].id,openModalNewGroup:true,dialogGroup:{name:this.state.groups[groupName][i].name,groupName:groupName},erroNewGroup:"",textDialog:"Editar grupo",groupError:""});

			}
		}

	}

	async getGroups(){

		await this.getAccessLevelGroup('school');
		await this.getAccessLevelGroup('center');

	}

	//para criar um novo grupo no sistem,
	async componentDidMount(){

		await this.getAllPermission();
		this.getGroups();

	}

	saveUser(event){

		event.preventDefault();
		if(this.state.groupEdit === ""){ this.postGroup(); }else{ this.putGroup(); }

	}

	render(){

		const handleChange = name => event => {
	        var dialogGroup = this.state.dialogGroup;
	        dialogGroup[name] = event.target.value;
	        this.setState({dialogGroup:dialogGroup});
	    }

		return(
		<React.Fragment>
		<div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
		<SimpleHeader name="Grupos e permissões" way={[{name:"Grupos",url:"/permission"}]} buttons={<React.Fragment></React.Fragment>} />
		<Col md={12}>
			<Row> 
			<Col md={6}>
				<Container className="mt--6" fluid>
					<Card>
					<CardHeader className="border-0">
						<Row>
						<Col xs="6"><h3 className="mb-0">Grupos central</h3></Col>
						<Col className="text-right" xs="6" hidden>
							<Button className="btn-neutral btn-round btn-icon" color="default" href="#pablo" id="tooltip969372949" onClick={() => this.newGroup('school')} size="sm">
							<span className="btn-inner--icon mr-1"><i class="fas fa-user-plus"></i></span>
							<span className="btn-inner--text">Criar grupo</span>
							</Button>
						</Col>
						</Row>
					</CardHeader>
					<Table className="align-items-center table-flush" responsive>
						<thead className="thead-light">
						<tr>
							<th>Nome</th>
							<th>Ações</th>
						</tr>
						</thead>
						<tbody>
						{
						this.state.groups.center.map((value,index) => {

							var tooltip = "tooltip_user_key_"+value.id;
							var tooltip2 = "tooltip2_user_key_"+value.id;

							return(<tr key={value.id}>

								<td className="table-user"><b>{value.name} {value.lastName}</b></td>
								<td className="table-actions">

									<a onClick={ () => this.editGroup('center',value.id) } className="table-action" href="#pablo" id={tooltip} ><i className="fas fa-pencil-alt" /></a>
									<UncontrolledTooltip delay={0} target={tooltip}>Editar grupo</UncontrolledTooltip>

									<a onClick={ () => this.editPermission(value.id,value.groupName) } className="table-action table-action-delete" href="#pablo" id={tooltip2} ><i class="fas fa-shield-alt"></i></a>
									<UncontrolledTooltip delay={0} target={tooltip2}>Editar permissões</UncontrolledTooltip>

								</td>

							</tr>)
						})
						}
						</tbody>
					</Table>
					</Card>
				</Container>
			</Col>
			<Col md={6}>
				<Container className="mt--6" fluid>
					<Card>
					<CardHeader className="border-0">
						<Row>
						<Col xs="6"><h3 className="mb-0">Grupos school</h3></Col>
						<Col className="text-right" xs="6" hidden>
							<Button className="btn-neutral btn-round btn-icon" color="default" href="#pablo" id="tooltip969372949" onClick={() => this.newGroup('school')} size="sm">
							<span className="btn-inner--icon mr-1"><i class="fas fa-user-plus"></i></span>
							<span className="btn-inner--text">Criar grupo</span>
							</Button>
						</Col>
						</Row>
					</CardHeader>
					<Table className="align-items-center table-flush" responsive>
						<thead className="thead-light">
						<tr>
							<th>Nome</th>
							<th>Ações</th>
						</tr>
						</thead>
						<tbody>
						{
						this.state.groups.school.map((value,index) => {

							var tooltip = "tooltip_user_key_"+value.id;
							var tooltip2 = "tooltip2_user_key_"+value.id;

							return(<tr key={value.id}>

								<td className="table-user"><b>{value.name} {value.lastName}</b></td>
								<td className="table-actions">

									<a onClick={ () => this.editGroup('school',value.id) } className="table-action" href="#pablo" id={tooltip} ><i className="fas fa-pencil-alt" /></a>
									<UncontrolledTooltip delay={0} target={tooltip}>Editar grupo</UncontrolledTooltip>

									<a onClick={ () => this.editPermission(value.id,value.groupName) } className="table-action table-action-delete" href="#pablo" id={tooltip2} ><i class="fas fa-shield-alt"></i></a>
									<UncontrolledTooltip delay={0} target={tooltip2}>Editar permissões</UncontrolledTooltip>

								</td>

							</tr>)
						})
						}
						</tbody>
					</Table>
					</Card>
				</Container>
			</Col>
			</Row>
		</Col>

        <Modal className="modal-dialog-centered" isOpen={this.state.openModalNewGroup} >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
            <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">{this.state.textDialog}</h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({openModalNewGroup:false})}><span aria-hidden={true}>×</span> </button>
          </div>
              <CardBody className="px-lg-5">
                <Form role="form" type="post" onSubmit={ (event) => this.saveUser(event) }>
                  {
                    (this.state.groupError) &&
                    <Alert color="danger" className="animated tada"><strong>Ops!</strong> {this.state.groupError}</Alert>
                  }
              
					<Col md={12}>
					<h5>Nome</h5>
					<FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
					  <InputGroup className="input-group-merge input-group-alternative">
					    <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
					    <Input type="text" onChange={handleChange('name')} value={ this.state.dialogGroup.name } onFocus={() => this.setState({ focusedName: true }) } onBlur={() => this.setState({ focusedName: false }) } />
					  </InputGroup>
					</FormGroup>
					</Col> 
            
            
                  <div className="text-center">
                    <Button className="my-4" color="default" type="button" onClick={ () => this.setState({openModalNewGroup:false}) } >Cancelar</Button>
                    <Button className="my-4" color="primary" type="submit">{this.state.textDialog}</Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>



        <Modal className="modal-dialog-centered" isOpen={this.state.openModalEditPermition} >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
            <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">Alterandos permissões de <strong>{this.state.editPermissionName}</strong></h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({openModalEditPermition:false})}><span aria-hidden={true}>×</span> </button>
          </div>
              <CardBody className="px-lg-5">
                <Form role="form" type="post" onSubmit={ (event) => this.savePermition(event) }>
					{
						this.state.permitions.map((value,index) => {
							
							var id = "input_permition_"+value.id; 

							//Para mostrar apenas as permissões do grupo que estou editando no momnento 
							if(this.state.showPermitionGrupo !== value.groupName){ return <React.Fragment key={id}></React.Fragment> }

							var check = false; 
							if(this.state.permChecks.indexOf(value.id) !== -1){ check = true; }

							return(<React.Fragment key={id}>
								
								<div onChange={ (event) => {

									var permChecks = this.state.permChecks;

									console.log(event.target.checked); 
									if(event.target.checked === true){

										permChecks.push(value.id);
										this.setState({permChecks});

									}else{

										permChecks.splice(permChecks.indexOf(value.id),1);
										this.setState({permChecks});

									}

								}} className="custom-control custom-checkbox">
						          <input checked={check} className="custom-control-input inputPermission" id={id} value={value.id} type="checkbox" />
						          <label className="custom-control-label" htmlFor={id}>{value.name}</label>
						        </div>

							</React.Fragment>)

						})
					}
                  <div className="text-center">
                    <Button className="my-4" color="default" type="button" onClick={ () => this.setState({openModalEditPermition:false}) } >Cancelar</Button>
                    <Button className="my-4" color="primary" type="submit">Salvar alterações</Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>









		   
		  </React.Fragment>
		);
	}
}
export default Tables;