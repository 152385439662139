import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button
} from "reactstrap";

class AdminNavbar extends React.Component {
  render() {
    return (
      <>
        <Navbar
          className="navbar-horizontal navbar-main navbar-dark navbar-transparent" expand="lg" id="navbar-main" >
          <Container>
            <NavbarBrand to="/" tag={Link}>
              <img alt="" src="https://engine2.csplus.school/media/csplus-logo.svg" style={{height:'90px'}}/>
            </NavbarBrand>
            <button aria-controls="navbar-collapse" aria-expanded={false} aria-label="Toggle navigation" className="navbar-toggler" data-target="#navbar-collapse" data-toggle="collapse" id="navbar-collapse" type="button" >
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse className="navbar-custom-collapse" navbar toggler="#navbar-collapse" >
              <div className="navbar-collapse-header">
                <Row>
         
                  <Col className="collapse-close" xs="12">
                    <button style={{position:'absolute',borderBottom:'1px solid rgba(0, 0, 0, 0)',left:'calc(100% - 25px)',top:'-12px'}} aria-controls="navbar-collapse" aria-expanded={false} aria-label="Toggle navigation" className="navbar-toggler" data-target="#navbar-collapse" data-toggle="collapse" id="navbar-collapse" type="button" >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="mr-auto" navbar>
                <NavItem>
                  <a className="nav-link" href="https://cs.plus/sistema" tag={Link}>
                    <span className="nav-link-inner--text">Home</span>
                  </a>
                </NavItem>
                <NavItem>
                  <a className="nav-link" href="https://cs.plus/sobre-nos" tag={Link}>
                    <span className="nav-link-inner--text">{window.language('sobre_nos')}</span>
                  </a>
                </NavItem>
                <NavItem>
                  <a className="nav-link" href="https://cs.plus/sistema" tag={Link}>
                    <span className="nav-link-inner--text">{window.language('sistema_de_ensino')}</span>
                  </a>
                </NavItem>
              </Nav>
              <hr className="d-lg-none" />
              <Nav className="align-items-lg-center ml-lg-auto" navbar>
                <NavItem>
                  <NavLink className="nav-link-icon" href="https://www.facebook.com/creativetim?ref=creative-tim" id="tooltip601201423" target="_blank" >
                    <i className="fab fa-facebook-square" />
                    <span className="nav-link-inner--text d-lg-none">
                      Facebook
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip601201423">
                    Like us on Facebook
                  </UncontrolledTooltip>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link-icon" href="https://www.instagram.com/creativetimofficial?ref=creative-tim" id="tooltip871243015" target="_blank" >
                    <i className="fab fa-instagram" />
                    <span className="nav-link-inner--text d-lg-none">
                      Instagram
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip871243015">
                    Follow us on Instagram
                  </UncontrolledTooltip>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link-icon" href="https://twitter.com/creativetim?ref=creative-tim" id="tooltip366258619" target="_blank" >
                    <i className="fab fa-twitter-square" />
                    <span className="nav-link-inner--text d-lg-none">
                      Twitter
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip366258619">
                    Follow us on Twitter
                  </UncontrolledTooltip>
                </NavItem> 
                <NavItem className="d-none d-lg-block ml-lg-4">
                  <Button className="btn-neutral btn-icon" color="default" href="https://forms.monday.com/forms/5f90eb50934e2e3f6b1d993a5e63c39c" target="_blank" >
                    <span className="btn-inner--icon">
                      <i className="ni ni-book-bookmark mr-2" />
                    </span>
                    <span className="nav-link-inner--text">{window.language('apresentacao')}</span>
                  </Button>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;