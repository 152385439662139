import React, { Component } from 'react';
import {Card,CardHeader,CardBody,Container,Row,Col,Button,Input,Modal,Table,UncontrolledTooltip,Progress } from "reactstrap";
import SimpleHeader from "./../../../../components/Headers/SimpleHeader.jsx";
import NotificationAlert from "react-notification-alert";
import api from "./../../../../Api.js"; 
import axios from "axios";

const Swal = require('sweetalert2');

class ManagerMedia extends Component {

    constructor(props) {

        super(props);
        this.state = {course:{},data:[],newFile:false,loadData:false,error:"",dialog:{name:"",description:""},loadingFile:false,progress:0};
        this.newFile = this.newFile.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.edit = this.edit.bind(this);
        this.removeMedia = this.removeMedia.bind(this);

        window.managermedia = this;

    } 

    edit(value){

      console.log(value);
      this.setState({dialog:JSON.parse(JSON.stringify(value)),newFile:true});

    }

    async uploadImg(input){

      console.log(input);

      let formData = new FormData();

   
      var file = window.$(input);
      console.log(file);
      console.log(file.files);
      //console.log(file[0].files[0]);

      //seta o erro caso nenhum arquivo tenha sido informado
      if(file[0].files.length === 0){

        //fileInput[n].erro = "";
        //this.setState({fileInput:fileInput});
        //setTimeout(function(){ fileInput[n].erro = "Você deve escolher um arquivo."; this.setState({fileInput:fileInput}); }.bind(this), 1);
        return false;

      }
      
      formData.append('files', file[0].files[0]); 
      formData.append('name', file[0].files[0].name);

      var setProgress = ((n) => {

        console.log('setou progresso');
        console.log(n);

        this.setState({loadingFile:true,progress:n});

      });

      setProgress(0);
    
      axios.post(sessionStorage.getItem('backend')+'/upload',formData,{headers:{'Content-Type': 'multipart/form-data',session:sessionStorage.getItem('session')},onUploadProgress:progressEvent => {
    
        setProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total))
    
      }}).then(function(res){
     
        console.log(res.data);
        this.state.dialog.file = res.data.id;
        this.state.dialog.dataFile = res.data;
        
        this.setState({loadingFile:false});
  
      }.bind(this)).catch(function(res){
    
        //console.log('FAILURE!!');
        //this.setProgress('',n);
    
      }.bind(this));
    
    }


    async newFile(){
      
      this.setState({newFile:true,dialog:{name:"",description:""},loadingFile:false});

    }

    async getCourse(){

        await api.getCourse(this.props.match.params.course).then((res) => {
              
          this.setState({course:res.data,loadCourse:true}); 
    
        }).catch(error => {
    
          console.log(error.data);
          console.log(error);
          console.log(error.response);
    
          if(error.response.status === 404){ this.props.history.push('/'); }
    
        });
    
    }

    componentDidUpdate(){}

    componentDidMount(){ 
        
        this.getCourse();
        this.getMedia();
    
    }

    removeMedia(id){
        
      Swal.fire({
          title: 'Tem certeza?',
          text: " ",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, remover!',
          cancelButtonText:"Não, cancelar"
      }).then((result) => {

          if(result.value){
              
              api.deleteMedia(id).then((res) => {
                  
                  let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O arquivo foi removido.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
                  this.refs.notificationAlert.notificationAlert(options);

                  this.getMedia();

              }).catch(error => {
      
                  console.log(error.data);
                  console.log(error);
                  console.log(error.response);

                  if(error.response !== undefined){

                    if(error.response.data !== undefined){
          
                      if(error.response.data.msg !== undefined){
                      
                        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Não foi possível cadastrar a lesson.</span><span data-notify="message">{error.response.data.msg}</span></div>),type: 'danger',icon: "fas fa-exclamation",autoDismiss: 7};
                        this.refs.notificationAlert.notificationAlert(options);
                      
                      }
          
                    }
          
                  }

              });

          }

      });
      
    }

    async getMedia(){

      await this.setState({loadData:false});
      await api.getMedia(this.props.match.params.course,this.props.type).then((res) => {
              
        this.setState({data:res.data,loadData:true}); 
  
      }).catch(error => {
  
        console.log(error.data);
        console.log(error);
        console.log(error.response);
  
        if(error.response.status === 404){ this.props.history.push('/'); }
  
      });

    }

    postMedia(){

      var obj = this.state.dialog;
      obj.course = this.props.match.params.course;
      obj.type = this.props.type;

      api.postMedia(obj).then(async (res) => { 

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">O arquivo foi cadastrado.</span></div>),type: 'success',icon: "fas fa-check",autoDismiss: 7};
			  this.refs.notificationAlert.notificationAlert(options);

        this.getMedia();
        this.setState({newFile:false});

        console.log(res.data);

      }).catch((error) => {

        if(error.response !== undefined){

          if(error.response.data !== undefined){

            if(error.response.data.msg !== undefined){

              this.setState({error:error.response.data.msg});

            }

          }

        }

      });

    }

    putMedia(){

      var obj = this.state.dialog;
      obj.course = this.props.match.params.course;
      obj.type = this.props.type;
      
      api.putMedia(this.state.dialog.id,obj).then(async (res) => { 

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">As alterações foram salvas.</span></div>),type: 'success',icon: "fas fa-check",autoDismiss: 7};
			  this.refs.notificationAlert.notificationAlert(options);

        this.getMedia();
        this.setState({newFile:false});

        console.log(res.data);

      }).catch((error) => {

        if(error.response !== undefined){

          if(error.response.data !== undefined){

            if(error.response.data.msg !== undefined){

              this.setState({error:error.response.data.msg});

            }

          }

        }

      });

    }

    saveFile(e){

      e.preventDefault();
      if(this.state.dialog.id === undefined){ this.postMedia(); }else{ this.putMedia(); }

    }

    componentWillUnmount(){}
    
    render(){

        var type = "";
        var type2 = "";
        var type3 = "";
        var accept = "";

        if(this.props.type === "audio"){ type = "Áudio"; type2 = "Áudios"; type3 = "audio"; accept = "audio/*"; }
        if(this.props.type === "video"){ type = "Vídeo"; type2 = "Vídeos"; type3 = "video"; accept = "video/*"; }
        if(this.props.type === "image"){ type = "Imagem"; type2 = "Imagens"; type3 = "image"; accept = "image/*"; }

 

        return (<React.Fragment>

            <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>

            <React.Fragment>
	  				<SimpleHeader name="Aulas" way={[{name:"Cursos",url:"/course"},{name:this.state.course.name,url:'/course/'+this.state.course.id},{name:type,url:'/course/'+this.state.course.id+'/'+type3}]} buttons={<React.Fragment>

              <button onClick={ () => this.newFile() } className="btn-secondary btn-round btn-icon btn btn-sm" size="sm" ><i className="fas fa-plus"></i> Novo {type.toLocaleLowerCase()}</button>

            </React.Fragment>} />
						<Container className="mt--6" fluid>
              <Row className="justify-content-center">
                <Col className="card-wrapper " lg="12">
							 		<Card>
                  <CardHeader className="border-0">
                    <Row>
                        <Col xs="6"><h3 className="mb-0">{type2}</h3></Col>
                        <Col className="text-right" xs="6">
                             
                        </Col>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                        <tr>
                        <th>Nome</th> 
                        <th>Descrição</th>
                        <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                    {

                      this.state.data.map((value,index) => { 

                        var id_tol_tip1 = "trash_"+value.id;
                        var id_tol_tip2 = "edit_"+value.id;
                        var id_tol_tip3 = "dl_"+value.id;

                        return(<tr key={index} >
                                <td className="table-user">{value.name}</td> 
                                <td><textarea readonly="true" className="form-control-alternative form-control" value={value.description}></textarea></td>
                                <td className="table-actions">

                                  <a className="table-action" id={id_tol_tip3} onClick={() => { window.open(sessionStorage.backend+value.dataFile.url, "MsgWindow", "width=400,height=400,top="+window.$(window).height()/2+",left="+window.$(window).width()/2) }} style={{color:"#525f7f",cursor:'pointer'}}><i className="fas fa-download"></i></a>
                                  <UncontrolledTooltip delay={0} target={id_tol_tip3}>Acessar</UncontrolledTooltip>

                                  <a style={{cursor:'pointer'}} className="table-action" id={id_tol_tip2} onClick={() => this.edit(value) } ><i className="fas fa-pencil-alt"></i></a>
                                  <UncontrolledTooltip delay={0} target={id_tol_tip2}>Edita</UncontrolledTooltip>

                                  <a style={{cursor:'pointer'}} className="table-action table-action-delete" id={id_tol_tip1} onClick={() => this.removeMedia(value.id) } ><i class="far fa-trash-alt"></i></a>
                                  <UncontrolledTooltip delay={0} target={id_tol_tip1}>Deletar</UncontrolledTooltip>

                                </td>
                              </tr>)

                      })

                    }
                    </tbody>
                  </Table>
                  {	

                    (this.state.data.length === 0) &&
                    <center style={{marginBottom:"15px",marginTop:"20px"}}><h3>Nenhum {type.toLocaleLowerCase()} foi encontrado.</h3></center>

                  } 
                </Card>
            </Col>
          </Row>
      </Container>
    </React.Fragment> 	


      
      {

        (this.state.newFile === true) &&
        <React.Fragment>
          <Modal className="modal-dialog-centered" isOpen={true} >
            <div className="modal-body p-0">
                <Card className="bg-secondary border-0 mb-0">
                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-default">{(this.state.dialog.id === undefined ? 'Cadastrando':'Editando')} {type.toLocaleLowerCase()}</h6>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({newFile:false})}><span aria-hidden={true}>×</span> </button>
                    </div>
                    <form onSubmit={ (event) => this.saveFile(event) }>
                        <div className="px-lg-5 card-body">
                            {

                                (this.state.error !== "") &&
                                <div class="alert alert-danger fade show" role="alert"><strong>Ops!</strong> {this.state.error}</div>

                            }
                            <div>
                                <h5>Nome:</h5>
                                <div class="input-group-merge input-group-alternative input-group"> 
                                    <input onChange={(e) => { this.state.dialog.name = e.target.value; this.setState({a:''}); }} value={this.state.dialog.name} placeholder="" type="text" class="form-control-alternative form-control" />
                                </div>
                            </div>
                            <div style={{marginTop:"10px"}}>
                                <h5>Descrição:</h5>
                                <div class="input-group-merge input-group-alternative input-group"> 
                                    <textarea onChange={(e) => { this.state.dialog.description = e.target.value; this.setState({a:''}); }} value={this.state.dialog.description} placeholder="" type="text" class="form-control-alternative form-control"></textarea>
                                </div>
                            </div>
                            {

                              (this.state.loadingFile === false) &&
                              <div style={{marginTop:"10px"}}>
                                  <h5>Arquivo:</h5>
                                  <div className="custom-file">
                                    <input onChange={(e) => this.uploadImg(e.target) } className="custom-file-input" id="customFileLangdda" type="file" accept={accept} />
                                    <label className="custom-file-label" htmlFor="customFileLangdda">{(this.state.dialog.dataFile !== undefined ? this.state.dialog.dataFile.name:"Selecione o arquivo")}</label>
                                  </div>
                              </div>

                            }
                            {

                              (this.state.loadingFile === true) &&
                              <div style={{marginTop:"10px"}}>
                                <div style={{textAlign:'center'}}>{this.state.progress}%</div>
                                <div><Progress max="100" value={this.state.progress} color="success" /></div>
                              </div>
                              
                            }
                            {

                              (this.state.dialog.file !== undefined && this.state.loadingFile === false) &&
                              <div class="text-center" style={{marginTop:'25px'}}><button type="submit" class="btn btn-primary">{(this.state.dialog.id === undefined ? 'Cadastrar':'Salvar alterações')}</button></div>

                            }
                        </div>
                    </form>
                </Card>
            </div>
          </Modal>
        </React.Fragment>

      }
                

      </React.Fragment>);

    }

}

export default ManagerMedia;