import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const arrayMove = require('array-move');
const uuidv4 = require('uuid/v4');


const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  //background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({/*display:'flex',background: isDraggingOver ? 'lightblue' : 'lightgrey',*/ });

class ExerciseCompleteVarious extends Component{

    constructor(props) {

        super(props);
        this.state = {};

        this.state.wordids = [];
        this.state.wordids2 = [];
         
        this.onDragEnd = this.onDragEnd.bind(this);
        this.add = this.add.bind(this);
        this.remove = this.remove.bind(this);

        window.exercisecompletevarious = this;

    }

    add(){

        this.props.state.dialogLesson.completevarious.push({

            list: [], //  texto que vai aparecer para ele
            answers: [],
            completList:[],
            extraAnswers:[],
            audio: null

        });

        this.setState({a:''});

    }

    remove(index){

        this.props.state.dialogLesson.completevarious.splice(index,1);
        this.setState({a:''});

    }

    onDragEnd(data){
       
        this.props.state.dialogLesson.completevarious = arrayMove(this.props.state.dialogLesson.completevarious,data.source.index,data.destination.index);
        this.state.wordids = arrayMove(this.state.wordids,data.source.index,data.destination.index);
        this.setState({a:""});

    }
    
    render() {

        var completevarious = this.props.state.dialogLesson.completevarious;
        if(completevarious === undefined){

            console.log('redefiniu');
            this.props.state.dialogLesson.completevarious = [{"list":["what","is","your","name?"],"answers":[],"audio":null,"completList":[1],"extraAnswers":['she','he']}];
            this.state.wordids = [uuidv4(),uuidv4()];
            //this.state.wordids2 = [];
  
        }

        completevarious = this.props.state.dialogLesson.completevarious;

        var lethers = ['A','B','C','D','E','F','G','H','I','J','L','M'];


        const undeln = ((number) => {

            var d = "__";
            for(let index = 0; index < number.length; index++){
                
                d = d+"_";
                
            }

            return d;
            
        })
         
        return(
        <React.Fragment>
  

            <div style={{marginTop:'20px'}}><h5>Exercícios:</h5></div>
            {

                (completevarious.length === 0) &&
                <React.Fragment>
                    <center><h4>Nenhum exercício foi cadastrado.</h4></center>
                </React.Fragment>

            }
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable" direction="vertical">
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps} >
                    {
                    
                        this.props.state.dialogLesson.completevarious.map((value, index) => {

                            if(this.state.wordids[index] === undefined){ this.state.wordids[index] = uuidv4(); }
                            
                            return(<Draggable key={this.state.wordids[index]+''} draggableId={this.state.wordids[index]+''} index={index}>
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps}  style={getItemStyle(snapshot.isDragging,provided.draggableProps.style)}>
                                            <div style={{position:'relative',position:'relative',boxShadow: '0px 0px 6px -4px #000',borderRadius: '15px',paddingTop:'5px'}}>
                                                <i class="fas fa-arrows-alt" style={{position:'absolute',left:'-20px'}} {...provided.dragHandleProps}></i>
                                                <i class="far fa-trash-alt" style={{position:'absolute',left:'-19px',top:'30px',cursor:'pointer'}} onClick={() => {

                                                    this.props.state.dialogLesson.completevarious.splice(index,1);
                                                    this.setState({a:'1'});

                                                }}></i>
                                                <div>
                                                    <div style={{borderRadius:'10px',padding:'0px 0px 0px 10px',paddingBottom:'1px'}}>
                                                        <h4>Atividade: {lethers[index]}</h4>
                                                        <h5>Lista de palavras:</h5>
                                                        <div>
                                                            <div>
                                                                <DragDropContext onDragEnd={(data) => { 
                                                                    

                                                                    this.props.state.dialogLesson.completevarious[index].list = arrayMove(this.props.state.dialogLesson.completevarious[index].list,data.source.index,data.destination.index);
                                                                    this.state.wordids2[index] = arrayMove(this.state.wordids2[index],data.source.index,data.destination.index);
                                                        
                                                                    var inx = this.props.state.dialogLesson.completevarious[index].completList.indexOf(data.source.index);
                                                                    var inx2 = this.props.state.dialogLesson.completevarious[index].completList.indexOf(data.destination.index);

                                                                    if(inx !== -1){
                                                                        
                                                                        if(inx !== -1){ this.props.state.dialogLesson.completevarious[index].completList[inx] = data.destination.index; }
                                                                        
                                                                    }

                                                                    if(inx2 !== -1){
                                                                        
                                                                        if(inx2 !== -1){ this.props.state.dialogLesson.completevarious[index].completList[inx2] = data.source.index; }
                                                                        
                                                                    }

                                                                    this.setState({a:""});
                                                                    
                                                                 }}>
                                                                    <Droppable droppableId={'droppable'} direction="horizontal">
                                                                        {(provided, snapshot) => (
                                                                        <div ref={provided.innerRef} style={{display:'flex'}} {...provided.droppableProps}>

                                                                            {

                                                                                this.props.state.dialogLesson.completevarious[index].list.map((valueList, indexList) => {

                                                                                    if(this.state.wordids2[index] === undefined){ 
                                                                                        
                                                                                        this.state.wordids2[index] = [uuidv4()];
                                                                                    
                                                                                    }else{

                                                                                        if(this.state.wordids2[index][indexList] === undefined){

                                                                                            this.state.wordids2[index][indexList] = uuidv4();

                                                                                        } 

                                                                                    }
                                                                                    
                                                                                    var idsa = uuidv4();
                                                                                    return( <Draggable key={this.state.wordids2[index][indexList]+''} draggableId={this.state.wordids2[index][indexList]+''} index={indexList}>
                                                                                                {(provided, snapshot) => (
                                                                                                    <div ref={provided.innerRef} {...provided.draggableProps}  style={getItemStyle(snapshot.isDragging,provided.draggableProps.style)}>
                                                                                                        <div style={{position:'relative'}}>
                                                                                                            <i class="fas fa-arrows-alt" style={{position:'absolute',left:'-20px'}} {...provided.dragHandleProps}></i>
                                                                                                            <i onClick={(e) => {

                                                                                                                this.props.state.dialogLesson.completevarious[index].list.splice(indexList,1);
                                                                                                                this.setState({a:""});

                                                                                                            }} class="far fa-trash-alt" style={{position:'absolute',left:'-20px',top:'30px',cursor:'pointer'}}></i>
                                                                                                        </div>
                                                                                                        <div> 
                                                                                                            <input style={{width:'130px'}} disabled={(this.props.state.dialogLesson.completevarious[index].completList.indexOf(indexList) !== -1 ? true:false)} type="text" className="form-control-alternative form-control" value={valueList} onChange={(e) => { 

                                                                                                                this.props.state.dialogLesson.completevarious[index].list[indexList] = e.target.value;
                                                                                                                this.setState({a:''}); 
                                                                                                                
                                                                                                            }}/>
                                                                                                        </div>
                                                                                                        <div style={{justifyContent:'center',display:'flex',position:'relative',zIndex: '9999'}}>
                                                                                                            <div className="custom-control custom-checkbox">
                                                                                                                <input onChange={(e) => {

                                                                                                                    if(e.target.checked === true){

                                                                                                                        this.props.state.dialogLesson.completevarious[index].completList.push(indexList);

                                                                                                                    }else{

                                                                                                                        var inx = this.props.state.dialogLesson.completevarious[index].completList.indexOf(indexList);
                                                                                                                        if(inx !== -1){ this.props.state.dialogLesson.completevarious[index].completList.splice(inx,1); }

                                                                                                                    }

                                                                                                                    this.setState({a:''});

                                                                                                                }} checked={(this.props.state.dialogLesson.completevarious[index].completList.indexOf(indexList) !== -1 ? true:false)} className="custom-control-input" id={idsa} type="checkbox" />
                                                                                                                <label className="custom-control-label" htmlFor={idsa}>Completar</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>)
                                                                                                }
                                                                                            </Draggable>)

                                                                                })

                                                                            }

                                                                        </div>)}
                                                                    </Droppable>
                                                                </DragDropContext>
                                                            </div>
                                                            {

                                                                (value.list.length === 0) &&
                                                                <React.Fragment>
                                                                    <center>
                                                                        <h5>Nenhuma palavra foi adicionada.</h5>
                                                                    </center>
                                                                </React.Fragment>

                                                            }
                                                             
                                                        </div>
                                                        
                                                        <h5>Texto formado:</h5>
                                                        <div style={{display:'flex'}}>
                                                            
                                                            {

                                                                (this.props.state.dialogLesson.completevarious[index].list.length === 0) &&
                                                                <React.Fragment>Nenhuma palavra foi adicionada.</React.Fragment>

                                                            }

                                                            {

                                                                this.props.state.dialogLesson.completevarious[index].list.map((value,indexdda) => {

                                                                    if(this.props.state.dialogLesson.completevarious[index].completList.indexOf(indexdda) === -1){

                                                                        return(<div style={{marginRight:'5px'}}>{value} </div>);

                                                                    }

                                                                    if(this.props.state.dialogLesson.completevarious[index].completList.indexOf(indexdda) !== -1){
                                                                    
                                                                        return(<div style={{top:'2px',marginRight:'5px',position:'relative',display:'flex',justifyContent:'center'}}>{undeln(value)} <div style={{top:'-2px',position:'absolute'}}>{value}</div></div>);
                                                                        
                                                                    }

                                                                    

                                                                })

                                                            }

                                                        </div>
                                                        <center> 
                                                            <button onClick={() => { value.list.push(''); this.setState({a:""}); }} class="btn btn-sm btn-default" style={{marginBottom:'10px'}}><i className="fas fa-plus"></i> Nova palavra</button>
                                                        </center>
                                                        <h5>Alternativas para escolher:</h5>
                                                        <div style={{marginBottom:'20px'}}>
                                                            <div style={{display:'flex'}}>
                                                            {

                                                                this.props.state.dialogLesson.completevarious[index].completList.map((valueddsa,indexddsa) => {

                                                                    return(<div style={{marginRight:'10px'}}>
                                                                                <div> 
                                                                                    <input style={{width:'130px',height:'25px'}} disabled={true} type="text" className="form-control-alternative form-control" value={this.props.state.dialogLesson.completevarious[index].list[valueddsa]} />
                                                                                </div>
                                                                            </div>)

                                                                })

                                                            }
                                                            {

                                                                this.props.state.dialogLesson.completevarious[index].extraAnswers.map((valueddsa,indexddsa) => {

                                                                    return(<div style={{marginRight:'10px'}}>
                                                                                <div> 
                                                                                    <input style={{width:'130px',height:'25px'}} onChange={(e) => { this.props.state.dialogLesson.completevarious[index].extraAnswers[indexddsa] = e.target.value; this.setState({a:''}); }} type="text" className="form-control-alternative form-control" value={this.props.state.dialogLesson.completevarious[index].extraAnswers[indexddsa]} />
                                                                                </div>
                                                                                <div style={{textAlign:'center'}}>
                                                                                    <i onClick={() => {

                                                                                        this.props.state.dialogLesson.completevarious[index].extraAnswers.splice(indexddsa,1);
                                                                                        this.setState({a:''});

                                                                                    }} style={{cursor:'pointer'}} class="far fa-trash-alt"></i>
                                                                                </div>
                                                                            </div>)

                                                                })

                                                            }
                                                            </div>

                                                            <center> 
                                                                <button onClick={() => { this.props.state.dialogLesson.completevarious[index].extraAnswers.push(''); this.setState({a:""}); }} class="btn btn-sm btn-default" style={{marginBottom:'10px',marginTop:'20px'}}><i className="fas fa-plus"></i> Adicionar alternativa</button>
                                                            </center>

                                                            <h5>Audio:</h5>
                                                            <div> 
                                                                <select onChange={(e) => {

                                                                    this.props.state.dialogLesson.completevarious[index].audio = e.target.value;
                                                                    this.setState({a:'12'});

                                                                    console.log(e.target.value);

                                                                }} style={{height:'25px',maxWidth:'300px',padding:'0px',paddingLeft:'10px'}} className="form-control-alternative form-control" value={this.props.state.dialogLesson.completevarious[index].audio} >
                                                                <option value="">{}</option>
                                                                {

                                                                    this.props.state.audios.map((value,index) => {

                                                                        return(<option value={value.id}>{value.name}</option>)

                                                                    })

                                                                } 
                                                                </select>
                                                            </div>


                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )}
                                </Draggable>)

                        })
                    
                    }
                    {provided.placeholder}
                    </div>
                )}
                </Droppable>
            </DragDropContext>

           

            <center><button style={{marginTop:'20px',width:'155px'}} color="primary" onClick={() => this.add()} className="btn btn-sm btn-secondary"><i className="fas fa-plus"></i> Adicionar Exercícios</button></center>

        </React.Fragment>
        );
    }

}

export default ExerciseCompleteVarious;