import React from 'react';
import { Button,Card,CardHeader,CardBody,FormGroup,Form,Input,Container,Row,Col} from "reactstrap";
import {Table,InputGroupText,Alert,Modal,InputGroupAddon,InputGroup,UncontrolledTooltip} from "reactstrap";
import classnames from "classnames";
import $ from 'jquery';
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "./../../../components/Headers/SimpleHeader.jsx";
import api from "./../../../Api.js";
import axios from 'axios';

class Videos extends React.Component {

  	constructor(props){

    	super(props);
		this.state = {
			titledialogVideo:"",
			erroVideo:"",
			buttondialogVideo:"",
			dialogVideo:{url:"",name:"",description:""},loadCourse:false,course:{},videos:[],openDialogRemoveVideo:false,opendialogVideo:false,editVideo:""};
 		
 		this.getCourse = this.getCourse.bind(this);
 		this.getVideos = this.getVideos.bind(this);

 		this.putVideo = this.putVideo.bind(this);
		this.postVideo = this.postVideo.bind(this);
		this.saveVideo = this.saveVideo.bind(this);

		this.getInfoVideo = this.getInfoVideo.bind(this);
		this.getInfoYoutube = this.getInfoYoutube.bind(this);
		this.removeVideoApi = this.removeVideoApi.bind(this);
		this.editVideo = this.editVideo.bind(this);

		window.videos = this;

  	}

  	//Para pegar os dados do curso
	async getCourse(){

		var course = await api.getCourse(this.props.match.params.course).then((res) => {
		    
			this.setState({course:res.data,loadCourse:true});
			console.log(res.data);
			return true;

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);
			//if(error.response.status === 404){ this.props.history.push('/'); }
			return false;

		});

		return course;

	}

	//Para pegar todos os vídeos do curso
	async getVideos(){

		var course = await api.getVideoCourse(this.props.match.params.course).then((res) => {

			this.setState({videos:res.data});
			console.log(res.data);
			return true;

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);
			//if(error.response.status === 404){ this.props.history.push('/'); }
			return false;
		});

		return course;

	}

	//Para atualizar o video no banco de dados
	async putVideo(){

		await api.putVideo(this.state.editVideo,this.state.dialogVideo).then((res) => {

			//Busca todas os vídeos
			this.getVideos();

			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso.</span><span data-notify="message">As alterações foram salvas.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);
			
			this.setState({opendialogVideo:false});

			console.log(res.data);
			return true;

	    }).catch(error => {

			console.log(error);
			console.log(error.response);
			if(error.response.status === 400){

			  //this.setState({erroUnit:''});
			  //setTimeout(function(){ this.setState({erroUnit:error.response.data.msg}); }.bind(this), 1);
			  this.setState({erroVideo:''});
              setTimeout(function(){ this.setState({erroVideo:error.response.data.msg}); }.bind(this), 1);

			}
			return false;

	    });


	}

	//Para cadastrar o video no banco de dados
	async postVideo(){

		this.state.dialogVideo.course = this.state.course.id;
		await api.postVideo(this.state.dialogVideo).then((res) => {

			//Busca todos os vídeos
			this.getVideos();

			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso.</span><span data-notify="message">O vídeo cadastrado.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);
			
			this.setState({opendialogVideo:false});

			console.log(res.data);
			return true;

	    }).catch(error => {

			console.log(error);
			console.log(error.response);
			if(error.response.status === 400){

			  //this.setState({erroUnit:''});
			  //setTimeout(function(){ this.setState({erroUnit:error.response.data.msg}); }.bind(this), 1);
			  this.setState({erroVideo:''});
              setTimeout(function(){ this.setState({erroVideo:error.response.data.msg}); }.bind(this), 1);

			}
			return false;

	    });

	}

	//Para abrir o modal de remover video
	removeVideo(id){

		this.setState({removeVideo:id,openDialogRemoveVideo:true});

	}

	//Para abrir o modal de cadastrar video
	newVideo(){

		this.setState({dialogVideo:{url:"",name:"",description:""},editVideo:"",opendialogVideo:true,titledialogVideo:"Novo vídeo",erroVideo:"",buttondialogVideo:"Cadastrar vídeo"});

	}

	//Para remover o video no banco de dados
	async removeVideoApi(){

		this.setState({openDialogRemoveVideo:false});

		var unit = await api.deleteVideo(this.state.removeVideo).then((res) => {

			this.getVideos();

			let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso.</span><span data-notify="message">O vídeo foi removido.</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
			this.refs.notificationAlert.notificationAlert(options);

			console.log(res.data);

			return true;

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);

			return false;

		});

		return unit;

	}

	//Para enviar o video para o banco de dados
	saveVideo(event){
 
		event.preventDefault();
		var dialogVideo = this.state.dialogVideo;
		if($('#auto_next').is(':checked') === true){ dialogVideo.autoNext = '1'; }else{ dialogVideo.autoNext = '0'; }
		this.setState({dialogVideo:dialogVideo});

		if(this.state.editVideo === ""){  this.postVideo(); }else{ this.putVideo();  }

	}

	async componentDidMount(){
  
    	await this.getCourse();
    	await this.getVideos();

	}

	//Para pegar informações do vimeo
	getInfoVimeo(n){

		axios.get('https://vimeo.com/api/v2/video/'+n+'.json').then(function(response){

		    console.log(response.data[0]);
		    console.log(response.data[0].title);
		    console.log(response.data[0].description);

		    var dialog = this.state.dialogVideo;
 			dialog.name = response.data[0].title;
 			dialog.description = response.data[0].description.replace(/<[^>]*>?/gm, '');
 			this.setState({dialogVideo:dialog});
 			console.log('setou stado');

		}.bind(this)).catch(error => {
      	
      		console.log(error.data);
      		console.log(error);
      		console.log(error.response);

    	});   

	}

	//Para pegar informacoes do youtbe
	getInfoYoutube(id){

		axios.get('https://www.googleapis.com/youtube/v3/videos?id='+id+'&key=AIzaSyCgFxvXobj5Tqt9xcY-xpYm0oCgEFo1IG0&part=snippet,statistics,contentDetails').then(function(response){

	 		if(response.data.items.length !== 0){

	 			var dialog = this.state.dialogVideo;
	 			dialog.name = response.data.items[0].snippet.title;
	 			dialog.description = response.data.items[0].snippet.description.replace(/<[^>]*>?/gm, '');
	 			this.setState({dialogVideo:dialog});

	 		}

		}.bind(this)).catch(error => {
	      
      		console.log(error.data);
      		console.log(error);
      		console.log(error.response);

	    });  


	}


	getInfoVideo(){

		var url = this.state.dialogVideo.url;

		//verificas se ja tem descrição ou name para evitar alterar o que ja foi alterado.
		if(this.state.dialogVideo.name !== "" || this.state.dialogVideo.description !== ""){ console.log(' n busca'); return false; }
 

		try {

			url = new URL(url);

			//verifica se a URL é do youtube
			if(url.hostname === "www.youtube.com" || url.hostname === "youtube.com"){

				console.log('é youtube');
				var video_id = url.href.split("v=")[1].substring(0, 11);
				this.getInfoYoutube(video_id);

			}

			//verifica se a url é do vimeo
			if(url.hostname === "vimeo.com"){

				//pega somente os numeros
				var numeros = url.pathname.replace(/\D/g,'');
				this.getInfoVimeo(numeros);

			}

		}
		catch(e){

		   	// declarações para manipular quaisquer exceções
		    // passa o objeto de exceção para o manipulador de erro
		    console.log(e);

		}



	}

	editVideo(id){

		this.setState({editVideo:id,opendialogVideo:true,titledialogVideo:"Editar vídeo",erroVideo:"",buttondialogVideo:"Salvar alterações"});

		this.state.videos.forEach(function(item,index,array){

			console.log('teste');

			if(parseInt(item.id) === parseInt(id)){

				var nitem = JSON.parse(JSON.stringify(item));
				console.log(nitem);
				delete nitem.course;
				delete nitem.createDate;
				delete nitem.updateDate;
				delete nitem.sequence;
				delete nitem.id;
				setTimeout(function(){
					if(parseInt(nitem.autoNext) === 1){ 
						$("#auto_next").prop('checked', true);
					}else{ 
						$("#auto_next").prop('checked', false); 
					}
				}, 500);
				
				this.setState({dialogVideo:nitem});

			}

		}.bind(this));

	}


  	render(){

  		const handleChange = name => event => {
	        var dialogVideo = this.state.dialogVideo;
	        dialogVideo[name] = event.target.value;
	        this.setState({dialogVideo:dialogVideo});
	        this.getInfoVideo();

	    }

  		return(<React.Fragment>
  			
  			<div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>

  			{
  				(this.state.loadCourse === true) &&
	  			<React.Fragment>
	  				<SimpleHeader name="Aulas" way={[{name:"Cursos",url:"/course"},{name:this.state.course.name,url:'/course/'+this.state.course.id},{name:'Vídeos',url:'/course/'+this.state.course.id+'/videos'}]} buttons={<React.Fragment></React.Fragment>} />
						<Container className="mt--6" fluid>
			          		<Row className="justify-content-center">
			            		<Col className="card-wrapper " lg="12">
							 		<Card>
							            <CardHeader className="border-0">
							              <Row>
							                <Col xs="6"><h3 className="mb-0">Vídeos</h3></Col>
							                <Col className="text-right" xs="6">
							                  <Button onClick={ () => this.newVideo() } className="btn-neutral btn-round btn-icon" color="default"  size="sm" >
							                    <span className="btn-inner--icon mr-1">
							                      <i className="fas fa-plus"></i>
							                    </span>
							                    <span className="btn-inner--text">Novo vídeo</span>
							                  </Button>
							                </Col>
							              </Row>
							            </CardHeader>
							            <Table className="align-items-center table-flush" responsive>
							              <thead className="thead-light">
							                <tr>
							                  <th>Nome</th>
							                  <th>URL</th>
							                  <th>Descrição</th>
							                  <th>Ações</th>
							                </tr>
							              </thead>
							              <tbody>
							              	{

							              		this.state.videos.map((value,index) => { 
													
							              			var img = "";
							              			try{
													   
							              				var url = new URL(value.url);
							              				if(url.host === "www.youtube.com"){ img = "https://findicons.com/files/icons/855/web_social/128/youtube.png"; }
						              					if(url.host === "vimeo.com"){ img = "https://findicons.com/files/icons/2788/circle/128/vimeo.png"; }
							              				 
													}
													catch(e){
														console.log(e);
													}

													var id_tol_tip1 = "trash_"+value.id;
													var id_tol_tip2 = "edit_"+value.id;

													return(<tr key={index} >
											                  <td className="table-user">
											                    <a alt="..." rel="noopener noreferrer" href={value.url} target="_blank"><img style={{backgroundColor:'#adb5bd00'}} alt="..." className="avatar rounded-circle mr-3" src={img} /></a>
											                    <b><a alt="..." rel="noopener noreferrer" href={value.url} target="_blank">{value.name}</a></b>
											                  </td>
											                  <td><a rel="noopener noreferrer" href={value.url} target="_blank">{value.url}</a></td>
											                  <td><textarea readonly="true" className="form-control-alternative form-control">{value.description}</textarea></td>
											                  
											                  <td className="table-actions">
											                    
											                    <a href="#marlon" style={{cursor:'pointer'}} className="table-action" id={id_tol_tip2} onClick={() => this.editVideo(value.id) } ><i className="fas fa-pencil-alt"></i></a>
											                    <UncontrolledTooltip delay={0} target={id_tol_tip2}>Edita vídeo</UncontrolledTooltip>
											                    
											                    <a href="#marlon" style={{cursor:'pointer'}} className="table-action table-action-delete" id={id_tol_tip1} onClick={() => this.removeVideo(value.id) } ><i class="far fa-trash-alt"></i></a>
											                    <UncontrolledTooltip delay={0} target={id_tol_tip1}>Deletar vídeo</UncontrolledTooltip>
											                  
											                  </td>
											                </tr>)

												})

							              	}
							              </tbody>
							            </Table>

							            {	


							            	(this.state.videos.length === 0) &&
							            	<center style={{marginBottom:"15px",marginTop:"20px"}}><h3>Nenhum vídeo foi encontrado.</h3></center>


							            }

							          </Card>
									</Col>
								</Row>
							</Container>
			   	</React.Fragment> 	
  			}

        <Modal className="modal-dialog-centered" isOpen={this.state.opendialogVideo} >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
            <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">{this.state.titledialogVideo}</h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({opendialogVideo:false})}><span aria-hidden={true}>×</span> </button>
          </div>
              <CardBody className="px-lg-5">
                <Form role="form" type="post" onSubmit={ (event) => this.saveVideo(event) }>
                  {
                    (this.state.erroVideo) &&
                    <Alert color="danger" className="animated tada"><strong>Ops!</strong> {this.state.erroVideo}</Alert>
                  }
                  <Row>		
	              		<Col md={12}>
	                        <h5>URL:</h5>
	                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedUrl})}>
                          		<InputGroup className="input-group-merge input-group-alternative">
                            		<InputGroupAddon addonType="prepend"><InputGroupText><i className="fas fa-link" /></InputGroupText></InputGroupAddon>
                        			<Input onKeyUp={this.getInfoVideo} type="text" onChange={handleChange('url')} value={ this.state.dialogVideo.url } onFocus={() => this.setState({ focusedUrl: true }) } onBlur={() => this.setState({ focusedUrl: false }) } />
                          		</InputGroup>
	                        </FormGroup>
	                  	</Col>
						<Col md={12}>
						<h5>Nome do vídeo:</h5>
							<FormGroup className={classnames("mb-3",{focused:this.state.focusedName})}>
						  	<InputGroup className="input-group-merge input-group-alternative">
					   	 		<InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
					   	 		<Input type="text" onChange={handleChange('name')} value={ this.state.dialogVideo.name } onFocus={() => this.setState({ focusedName: true }) } onBlur={() => this.setState({ focusedName: false }) } />
						  	</InputGroup>
							</FormGroup>
						</Col>
						<Col md={12}>
							<h5>Descrição:</h5>
							<Input className="form-control-alternative" onChange={handleChange('description')} value={ this.state.dialogVideo.description } placeholder="" rows="3" type="textarea" />
						</Col>
						<Col md={12} style={{marginTop:'10px'}}>
					     	<div className="custom-control custom-checkbox">
				          		<input className="custom-control-input" id="auto_next" type="checkbox" />
				          		<label className="custom-control-label" htmlFor="auto_next">Avançar ativiadades ao finalizar.</label>
					        </div>
						</Col>
                  </Row> 
                  <div className="text-center">
                    <Button className="my-4" color="default" type="button" onClick={ () => this.setState({opendialogVideo:false}) } >Cancelar</Button>
                    <Button className="my-4" color="primary" type="submit">{this.state.buttondialogVideo}</Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
        <Modal className="modal-dialog-centered modal-danger" contentClassName="bg-gradient-danger" isOpen={this.state.openDialogRemoveVideo} >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">Sua atenção é requirida.</h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggleModal("notificationModal")}>
              <span aria-hidden={true} onClick={ () => this.setState({openDialogRemoveUser:false}) }>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="fas fa-exclamation-triangle fa-x3" style={{fontSize:'45px'}}></i>
              <h4 className="heading mt-4">VOCÊ DEVE LER ISTO!</h4>
              <p>Se você prosseguir, todos os dados do vídeo serão removidos, se existir uma aula, que tenha esse vídeo, ela ficará sem o vídeo, é aconselhável alterar a URL ao invés deletar, caso só queria "atualizar" o vídeo.</p>
            </div>
          </div>
          <div className="modal-footer">
            <Button className="btn-white" color="default" type="button" onClick={ () => this.removeVideoApi() }>Ok, Remova</Button>
            <Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={ () => this.setState({openDialogRemoveVideo:false}) }>
              Cancelar e fechar
            </Button>
          </div>
        </Modal>

  		</React.Fragment>)
  	}
}
export default Videos;