//import Unit_helper from './redux/Unit_helper.js';
//import Lesson_helper from './redux/Lesson_helper.js';
//import Calendar_helper from './redux/Calendar_helper.js';
//import Chart_helper from './redux/Chart_helper.js';
//import Preference_helper from "./redux/Preference_helper.js";
//import ClassRom_helper from "./redux/ClassRom_helper.js";
//import User_helper from "./redux/User_helper.js";
import Course_helper from "./redux/Course_helper.js";
import License_helper from "./redux/License_helper.js";

function ReduxHelper(props){
		
	this.props = props;

}

ReduxHelper.prototype.course = function(){

	const course = new Course_helper(this.props);
	return course;

};

ReduxHelper.prototype.license = function(){

	const license = new License_helper(this.props);
	return license;

};

/*
ReduxHelper.prototype.classroom = function(){

	const unit = new ClassRom_helper(this.props);
	return unit;

};

ReduxHelper.prototype.unit = function(){

	const unit = new Unit_helper(this.props);
	return unit;

};

ReduxHelper.prototype.lesson = function(){

	const lesson = new Lesson_helper(this.props);
	return lesson;

};

ReduxHelper.prototype.calendar = function(){

	const calendar = new Calendar_helper(this.props);
	return calendar;

};

ReduxHelper.prototype.chart = function(){

	const chart = new Chart_helper(this.props);
	return chart;

};

ReduxHelper.prototype.preference = function(){

	const preference = new Preference_helper(this.props);
	return preference;

};
*/

export default ReduxHelper;