import React from "react";
import api from "./../../Api.js";
import moment from "moment"; 
import {Alert,Modal,CardBody,FormGroup,InputGroupAddon,Form,Input,InputGroupText,InputGroup,Button,Card,CardHeader,Table,Container,Row,Col,UncontrolledTooltip} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import Select from 'react-select';
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";

class Bncc_competencia extends React.Component {

  constructor(props){

        super(props);

        this.getHabilidades = this.getHabilidades.bind(this); 
        this.new = this.new.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.state = {habilidades:[],competencias:[],dialog:{competencias:[]}};

        this.post = this.post.bind(this);
        this.put = this.put.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.deleteHabilidade = this.deleteHabilidade.bind(this)
        this.getCompetencias = this.getCompetencias.bind(this); 

        window.license = this;


  }
  
  //para buscar todos os usuarios via api
  async getCompetencias(){

      await api.listCompetencias().then((res) => {

        this.setState({competencias:res.data});

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

      });
    
  }

  //para buscar todos os usuarios via api
  async getHabilidades(){

      await api.getHabilidades().then((res) => {

        this.setState({habilidades:res.data});

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

      });
    
  }

  delete(id){

    this.setState({openDialogRemove:true,deletion:id});

  }
  
  deleteHabilidade(){

    api.deleteHabilidade(this.state.deletion).then((res) => {
 
        this.getHabilidades();
        this.setState({openDialogRemove:false})

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">A habilidade foi deletada.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

    }).catch((error) => {

        window.Swal.fire('Ops!',error.response.data.msg,'error');
        this.setState({load:false});

    });

  }
 
  //para criar um novo usuario no sistem,
  async componentDidMount(){
  
    this.getHabilidades();
    this.getCompetencias();

  }

  new(){

    this.setState({
        dialog:{post:true,competencias:[]},
        openModal:true,
        
    });

  }

  handleChange(event,name){

    var dialog = this.state.dialog;
    dialog[name] = event.target.value;
    this.setState({dialog:dialog});

  }

  post(){

    if(this.state.load === true){ return false; }

    api.postHabilidades(this.state.dialog).then((res) => {

        this.setState({load:false,openModal:false});
        this.getHabilidades();

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">A habilidade foi cadastrada.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

    }).catch((error) => {

        window.Swal.fire('Ops!',error.response.data.msg,'error');
        this.setState({load:false});

    });

  }

  put(){

    if(this.state.load === true){ return false; }

    api.putHabilidades(this.state.dialog.id,this.state.dialog).then((res) => {
 
        this.setState({load:false,openModal:false});
        this.getHabilidades();

        let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">Sucesso!</span><span data-notify="message">As alterações foram salva.</span></div>),type: 'success',icon: "fas fa-exclamation",autoDismiss: 7};
        this.refs.notificationAlert.notificationAlert(options);

    }).catch((error) => {

         window.Swal.fire('Ops!',error.response.data.msg,'error');
        this.setState({load:false});

    });

  }

  save(e){

    e.preventDefault();
    if(this.state.dialog.post === true){

        this.post();

    }else{

        this.put();

    }

  }
 
  render(){

    var buttons = <React.Fragment></React.Fragment>;
    
    console.log('this.state.habilidades');
    console.log(this.state.habilidades);

    console.log('this.state.dialog.competencias');
    console.log(this.state.dialog.competencias);

    return(
      <React.Fragment>
        
        <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
        <SimpleHeader name="Habilidades" way={[{name:"Habilidades",url:"/license"}]} buttons={buttons} />
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6"><h3 className="mb-0">Lista de Habilidades</h3></Col>
                <Col className="text-right" xs="6">
                  <Button className="btn-neutral btn-round btn-icon" color="default" href="#pablo" id="tooltip969372949" onClick={() => this.new()} size="sm">
                    <span className="btn-inner--icon mr-1"><i class="fas fa-plus"></i></span>
                    <span className="btn-inner--text">Nova Habilidade</span>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th>id</th>
                  <th>Nome</th>
                  <th>Descrição</th>
                  <th><center>Ações</center></th>
                </tr>
              </thead>
              <tbody>
              {

                this.state.habilidades.map((value,index) => {
 
                  return(<tr key={index}>
                        <td>{value.id}</td>
                        <td>{value.name}</td>
                        <td><textare className="form-control">{value.description}</textare></td>
                        <td className="table-actions">
                            <center>
                                <font style={{cursor:'pointer'}} onClick={ () => { this.setState({dialog:JSON.parse(JSON.stringify(value)),openModal:true}) }} className="table-action" id={'aa'} ><i className="fas fa-pencil-alt"/></font>
                                <UncontrolledTooltip delay={0} target={'aa'}>Editar</UncontrolledTooltip>
                                <font style={{cursor:'pointer'}} onClick={ () => this.delete(value.id)} className="table-action table-action-delete" id={'ds'} ><i className="fas fa-trash" /></font>
                                <UncontrolledTooltip delay={0} target={'ds'}>Deletar</UncontrolledTooltip>
                            </center>
                        </td>
                    </tr>)

                  })}

              </tbody>
              {

                    (this.state.habilidades.length === 0) &&
                    <tbody>
                    <tr>
                        <td colSpan="4"><center>Nenhum registro encontrado</center></td>
                    </tr>
                    </tbody>

                }
            </Table>
          </Card>
        </Container>
        {

            (this.state.openModal === true) &&
            <Modal className="modal-dialog-centered" isOpen={this.state.openModal} toggle={() => this.setState({openModal:false})}>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{ this.state.dialog.post === true ? 'Nova habilidade':'Editar habilidade'}</h5>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.setState({openModal:false})}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                <form onSubmit={(e) => { this.save(e); }}>
                    <div className="modal-body">
                    <Row> 
                        <Col md={12}>
                            <h5>Alias</h5>
                            <FormGroup >
                                <InputGroup className="input-group-merge input-group-alternative">
                                    <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                                    <Input disabled={this.state.dialog.post !== true ? true:false} type="text" onChange={(e) => { this.handleChange(e,'id') }} value={this.state.dialog.id} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <h5>Nome</h5>
                            <FormGroup >
                                <InputGroup className="input-group-merge input-group-alternative">
                                    <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                                    <Input type="text" onChange={(e) => { this.handleChange(e,'name') }} value={this.state.dialog.name} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <h5>Descrição</h5>
                            <FormGroup >
                                <InputGroup className="input-group-merge input-group-alternative">
                                    <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                                    <textarea className="form-control" type="text" onChange={(e) => { this.handleChange(e,'description') }} value={this.state.dialog.description} ></textarea>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <h5>Competencias</h5>  
                            <Select
                                
                              isMulti={true} 
                              options={this.state.competencias.map((value) => { return {value:value.id,label:value.name} })}
                              onChange={(data) => {

                                console.log('data');
                                console.log(data);

                                var dialog = this.state.dialog;
                                dialog.competencias = data.map((value) => { return value.value });
                                this.setState({dialog:dialog});

                              }}

                              defaultValue={this.state.dialog.competencias.map((value) => { 
                                
                                var find = this.state.competencias.find((value2) => { return value2.id === value });
                                console.log('findcompetenciascompetencias');
                                console.log(find);
                                return {value:find.id,label:find.name}

                              })}
                                
                            />
                        </Col>
                        
                    </Row>
                    </div>
                    <div className="modal-footer">
                        <Button color="secondary" data-dismiss="modal" type="button" onClick={() => this.setState({openModal:false})}>Cancelar</Button>
                        <Button color="primary" type="submit">Salvar</Button>
                    </div>
                </form>
            </Modal>

        }

        <Modal className="modal-dialog-centered modal-danger" contentClassName="bg-gradient-danger" isOpen={this.state.openDialogRemove} >
            <div className="modal-header">
                <h6 className="modal-title" id="modal-title-notification">Sua atenção é requirida.</h6>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.toggleModal("notificationModal")}>
                    <span aria-hidden={true} onClick={ () => this.setState({openDialogRemove:false}) }>×</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="py-3 text-center">
                    <i class="fas fa-exclamation-triangle fa-x3" style={{fontSize:'45px'}}></i>
                    <h4 className="heading mt-4">VOCÊ DEVE LER ISTO!</h4>
                    <p>Se você prosseguir, todos os dados serão removidos, essa ação é irreversível.</p>
                </div>
            </div>
            <div className="modal-footer">
                <Button className="btn-white" color="default" type="button" onClick={ () => this.deleteHabilidade() }>Ok, Remova</Button>
                <Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={ () => this.setState({openDialogRemove:false}) }>Cancelar e fechar</Button>
            </div>
        </Modal>
      </React.Fragment>

    );
  }
}
export default Bncc_competencia;